const serviceConfig = {
  moodleService: {
    baseURL: 'https://iomod-lms.herovired.com/webservice/rest/server.php'
    // Please add Other configuration specific to the moodle service...
  },
  vAssistService: {
    baseURL: 'https://vlearn-api.herovired.com'
    // Please add configuration specific to the vAssist service...
  },
  moodleFreeVlearnService: {
    baseURL: 'https://previewvlearn.herovired.com/webservice/rest/server.php'
  },

  freeCourseArticle: {
    baseURL: 'https://staging.herovired.com/wp-json/wp/v2/blogs'
  },
  PrimerootEnrollmentService: {
    baseURL:
      'https://primeroot.herovired.com/primeroot-website-leads/lead-update'
  },
  SpringBootToNodeApis: {
    baseURL: 'https://datapipeline-staging.herovired.com'
  },
  VlearnProfileService: {
    baseURL: 'https://primeroot.herovired.com/primeroot-vlearn-profile'
  },
  VRiseService: {
    baseURL: 'https://career-portal-staging.herovired.com/career/'
  },
  VauthNodeApis: {
    baseURL: 'https://vauth-service.herovired.com'
  },
  VlearnPrimerootZoomSignature: {
    baseURL: 'https://primeroot.herovired.com/primeroot-zoom'
  }
};

const backendBaseUrl = 'https://iomod-lms.herovired.com';
const freeBackendBaseUrl = 'https://previewvlearn.herovired.com';
const backendApiUrl = serviceConfig.moodleService.baseURL;
const assetUrl = 'https://assets.herovired.com/vlearn';
const heroViredWebUrl = 'https://herovired.com';
const vLearnWebUrl = 'https://iomod-lms.herovired.com';
const vLearnApiUrl = serviceConfig.vAssistService.baseURL;
const vAssistUrl = 'https://api.herovired.com';
const notificationUrl = 'https://notification-service.herovired.com';
const PUBLIC_URL = window.location.origin;
const ZOOM_ENV = {
  SDK_KEY: 'FBfOrh9wSIC2ImExIXgnvw',
  SECRET_KEY: 'ltMrK80UWjm4sd9y2WLpoFuqBYN23vWN'
};
const CLEVERTAP_ACCOUNT = {
  ACCOUNT_ID: '4W9-K44-RW7Z',
  REGION: 'in1'
};

export {
  serviceConfig,
  backendBaseUrl,
  freeBackendBaseUrl,
  backendApiUrl,
  heroViredWebUrl,
  vLearnWebUrl,
  vLearnApiUrl,
  vAssistUrl,
  ZOOM_ENV,
  CLEVERTAP_ACCOUNT,
  assetUrl,
  PUBLIC_URL,
  notificationUrl
};
