import React from 'react';
import './appInput.scss';

const AppInput = ({
  label,
  required,
  name,
  register = () => {},
  errors = {},
  control = {},
  className = '',
  ...rest
}) => {
  return (
    <div className={`input-container ${className}`}>
      <label htmlFor={name} className='input-label'>
        {label} {required && <span className='required'>*</span>}
      </label>
      <input
        className={`input-field ${errors[name] ? 'input-error' : ''}`}
        {...register(name)}
        {...rest}
        autoComplete='autoCompleteStyle'
      />
      {errors[name] && <div className='error-message'>Invalid Value</div>}
    </div>
  );
};

export default AppInput;
