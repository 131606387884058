import React, { useRef, useState, useEffect } from 'react';
import Temp from '../Temp/Temp';
import 'handsontable/dist/handsontable.full.css';
import { registerAllModules } from 'handsontable/registry';
import HyperFormula from 'hyperformula';
import FormulaBar from '../FormulaBar/FormulaBar';
import '../Temp/temp.scss';
import './handsonTable.scss';
import OutputTerminal from '../OutputTerminal/OutputTerminal';
import ExcelTabSelection from '../ExcelTabSelection/ExcelTabSelection';
import SplitPane from 'react-split-pane';
import GuidedTour from '../GuidedTour/GuidedTour';
import backgroundExcel from '../../asset/images/backgroundExcel.svg';
import darkBackground from '../../asset/images/darkBackground.svg';
import { useSelector } from 'react-redux';
registerAllModules();

function HandsonTableParent({
  attemptData = null,
  apiData = {
    data: {
      cellData: [
        ['John Doe', 'johndoe@gmail.com', 28, 5000, 500, '=D1+E1'],
        ['Jane Smith', 'janesmith@gmail.com', 34, 6000, 600, '=D2+E2'],
        ['Paul Walker', 'paulwalker@gmail.com', 41, 7000, 700, '=D3+E3'],
        ['Lisa Brown', 'lisabrown@gmail.com', 22, 4000, 400, '=D4+E4'],
        ['Michael Green', 'michaelgreen@gmail.com', 38, 5500, 550, '=D5+E5']
      ],
      headers: ['Name', 'Email', 'Age', 'Salary', 'Bonus', 'Total Salary'],
      dimensions: { rows: 30, columns: 15 },
      description: [
        `There are N items in the magical pool where ith item has A[i][0]
        quality and A[i][1] quantity. Robin trying to be greedy wants to
        maximize the total quantity of items picked but he can only pick one
        item at a time with the highest quality available in the pool with
        corresponding quantity. Let's say the quality of item picked is X.
        The pool is magical and all items in the pool with similar quality
        disappears and a new item with quality = floor(X/2) and quantity = X
        * 2 is added. Robin will never choose the item with quality 0.
        Return the maximum quantity of items Robin can pick. Since the
        answer could be large, return answer % 109 + 7.`
      ]
    },
    validations: {
      F1: {
        formula: { value: '=D1+E1', flag: true },
        output: { value: '5500', flag: true },
        mm: '50'
      },
      F2: {
        formula: { value: '=D2+E2', flag: true },
        output: { value: '6600', flag: false },
        mm: '30'
      },
      F3: {
        formula: { value: '=D3+E3', flag: false },
        output: { value: '7700', flag: true },
        mm: '50'
      }
    }
  }
}) {
  let arr = [];
  let alphabets = [];
  for (let i = 0; i < apiData?.data?.dimensions?.rows; i++) {
    arr.push(new Array(apiData?.data?.dimensions?.columns)?.fill(''));
    if (i < apiData?.data?.dimensions?.columns) {
      alphabets.push(String.fromCharCode((i % 26) + 65));
    }
  }
  const [pivotConfig, setPivotConfig] = useState({});
  const [cellHash, setCellHash] = useState({});
  const [messages, setMessages] = useState([]);
  const [isPivotOpen, setIsPivotOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('console');
  const [runClicked, setRunClicked] = useState(false); //
  const [storedMessages, setStoredMessages] = useState([]);
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [excelActiveTab, setExcelActiveTab] = useState('New Sheet');
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [submissionData, setSubmissionData] = useState({});
  const hfInstance = HyperFormula.buildEmpty();
  const hotRef = useRef(null);
  const [cellReference, setCellReference] = useState('A1');
  const [visitedOnce, setVisitedOnce] = useState(false);
  const [formulaBarText, setFormulaBarText] = useState(
    apiData.data.cellData[0][0] || ''
  );
  const [marks, setMarks] = useState(0);
  const generateTableData = () => {
    // Insert headers as the first row
    return [
      apiData.data.headers, // This will be the first row (A1)
      ...apiData.data.cellData // Actual data starts from A2
    ];
  };
  const [tableData, setTableData] = useState(generateTableData());
  // const [tableData, setTableData] = useState([
  //   ...apiData?.data?.cellData,
  //   ...arr
  // ]);
  const [tableDataForPivot, setTableDataForPivot] = useState([
    ...apiData?.data?.cellData,
    ...arr
  ]);
  useEffect(() => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      hotInstance.selectCell(0, 0); // Select "A1" on first load
    }
  }, []);
  const colHeaders = [];
  const hotSettings = {
    data: tableData,
    colHeaders,
    rowHeaders: true,
    filters: true,
    dropdownMenu: {
      items: {
        alignment: {},
        filter_by_condition: {},
        filter_by_value: {},
        filter_action_bar: {}
      }
    },
    columnSorting: false,
    contextMenu: false,
    selectionMode: 'multiple',
    formulas: {
      engine: hfInstance
    },
    nestedHeaders: [alphabets],
    columns: new Array(apiData?.data?.dimensions?.columns)
      ?.fill(0)
      ?.map((obj) => ({ type: 'text' })),
    afterFilter: (conditions) => {
      setIsFilterApplied(conditions && conditions.length > 0);
    },
    autoWrapRow: true,
    autoWrapCol: true,
    autoRowSize: false,
    autoColumnSize: true,
    //  manualRowResize: true,
    manualColumnResize: true,
    width: '100%',
    height: '100vh',
    licenseKey: 'b30c0-708f8-8d732-c910f-06044'
  };

  function detectAndSummarizeRepetitions(messages) {
    let n = messages.length;
    let pattern = [];
    let patternStartIndex = -1;

    for (let i = 1; i <= n / 2; i++) {
      let firstChunk = messages.slice(0, i);
      let secondChunk = messages.slice(i, i + i);

      if (JSON.stringify(firstChunk) === JSON.stringify(secondChunk)) {
        pattern = firstChunk;
        patternStartIndex = i;
        break;
      }
    }

    if (patternStartIndex === -1) {
      return messages;
    }

    let count = 1;
    let currentIndex = patternStartIndex + pattern.length;
    while (
      currentIndex + pattern.length <= n &&
      JSON.stringify(pattern) ===
        JSON.stringify(
          messages.slice(currentIndex, currentIndex + pattern.length)
        )
    ) {
      count++;
      currentIndex += pattern.length;
    }

    let uniqueMessages = messages.slice(0, patternStartIndex);

    return [...uniqueMessages];
  }

  const theme = useSelector((state) => state?.dashboard?.theme);
  function validateFormulaAndOutput(enteredFormula, enteredOutput, item) {
    let result = {
      className: '',
      weightage: 0,
      feedback: ''
    };
    let outputCorrect =
      enteredOutput?.toString()?.toLowerCase()?.replace(/\s+/g, '') ===
      item?.output?.value?.toString()?.toLowerCase()?.replace(/\s+/g, '');

    if (!outputCorrect) {
      result.className = 'red-highlight';
      result.feedback =
        item.output.feedback_incorrect_output ||
        'Your output is incorrect. Check your formula, references, and logic. Review the instructions and try again.';
      result.weightage = 0;
      return result;
    }
    let normalizedFormula = enteredFormula
      ?.toString()
      ?.toLowerCase()
      ?.replace(/\s+/g, '');
    let bestMatch = null;

    item.formula.options.forEach((option) => {
      let normalizedOption = option?.value.toLowerCase().replace(/\s+/g, '');
      if (normalizedFormula === normalizedOption) {
        if (
          !bestMatch ||
          Number(option?.weightage) > Number(bestMatch?.weightage)
        ) {
          bestMatch = option;
        }
      }
    });

    if (bestMatch) {
      result.weightage =
        Number(bestMatch.weightage) + Number(item?.output?.weightage);
      result.feedback = bestMatch?.feedback;
      if (Number(bestMatch.weightage) === Number(item?.formula?.mm)) {
        result.className = 'green-highlight';
      } else {
        result.className = 'light-blue-highlight';
      }
    } else {
      result.weightage = Number(item.output.weightage);
      result.feedback =
        item.output.feedback_correct_output ||
        "Your output is correct, but the formula used isn't recommended. Try using a different approach.";
      result.className = 'light-blue-highlight';
    }

    return result;
  }

  const applyCellStyles = (formulaData, valueData, check = '') => {
    document?.documentElement?.setAttribute('excel-theme', 'compiled');
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      const validations = apiData?.validations;

      let totalCells = 0;
      let passedFormulaCount = 0;
      let passedOutputCount = 0;
      let totalScore = 0;
      let tempMessages = [];

      hotInstance.updateSettings({
        cells: (row, col) => {
          const cellMeta = {};
          let colAlpha = String.fromCharCode((col % 26) + 65);
          let cellKey = `${colAlpha}${row + 1}`;
          let item = validations[cellKey];
          let cellMessages = [];
          if (!item) {
            cellMeta.readOnly = true;
            return cellMeta;
          }
          let enteredFormula = formulaData?.[row]?.[col] || '';
          let correctFormula = item?.formula?.value;
          if (check === 'solution') {
            if (correctFormula) {
              cellMeta.className = 'green-highlight';

              let correctValue = item?.output?.value || '';
              cellMeta.renderer = (
                instance,
                td,
                row,
                col,
                prop,
                value,
                cellProperties
              ) => {
                const isSelected =
                  instance.getSelected() &&
                  instance
                    .getSelected()
                    .some(
                      (selection) =>
                        selection[0] === row && selection[1] === col
                    );

                td.textContent = isSelected ? correctFormula : correctValue;
                td.classList.add('green-highlight');
              };
              cellMeta.readOnly = true;
              return cellMeta;
            }

            return cellMeta;
          }
          if (item) {
            totalCells++;

            let formulaMetaTrue = item?.formula?.flag;
            let outputMetaTrue = item?.output?.flag;
            let enteredFormula = formulaData[row][col]?.toString().trim();
            let enteredOutput = valueData[row][col]?.toString().trim();

            let validationResult = validateFormulaAndOutput(
              enteredFormula,
              enteredOutput,
              item
            );
            let testCaseNumber = 1;
            let cellMessages = [];
            cellMessages.push(`Checking input at cell ${cellKey}… ✅`);
            if (formulaMetaTrue) {
              cellMessages.push(
                `&nbsp;&nbsp;&nbsp;&nbsp;Verifying formula syntax... 🔍`
              );
              cellMessages.push(
                `&nbsp;&nbsp;&nbsp;&nbsp;Formula Entered: \"${
                  enteredFormula || ''
                }\"`
              );
            }

            cellMessages.push(`<br>Evaluating output ⚡`);
            cellMessages.push(
              `&nbsp;&nbsp;&nbsp;&nbsp;Output Entered: \"${
                enteredOutput || ''
              }\"`
            );
            cellMessages.push(`<br>Running test cases... ⏳`);

            let cellScore = validationResult.weightage;

            cellMeta.className = validationResult.className;
            if (formulaMetaTrue) {
              if (!enteredFormula) {
                cellMessages.push(
                  `&nbsp;&nbsp;&nbsp;&nbsp;Test case #${testCaseNumber}: Failed ❌`
                );
                cellMessages.push(
                  `Oops! There's a mistake in your formula. Check cell ${cellKey}.`
                );
              } else if (
                validationResult.className === 'green-highlight' ||
                validationResult.className === 'light-blue-highlight'
              ) {
                cellMessages.push(
                  `&nbsp;&nbsp;&nbsp;&nbsp;Test case #${testCaseNumber}: Passed! ✅`
                );
                cellMessages.push(`Feedback: ${validationResult.feedback}`);
              } else {
                cellMessages.push(
                  `&nbsp;&nbsp;&nbsp;&nbsp;Test case #${testCaseNumber}: Failed ❌`
                );
                cellMessages.push(
                  `Oops! There's a mistake in your formula. Check cell ${cellKey}.`
                );
              }
              testCaseNumber++;
            }
            if (outputMetaTrue) {
              if (enteredOutput === item?.output?.value.toString()) {
                cellMessages.push(
                  `&nbsp;&nbsp;&nbsp;&nbsp;Test case #${testCaseNumber}: Passed! ✅`
                );
                cellMessages.push(
                  `Expected: \"${item?.output?.value}\", Found: \"${enteredOutput}\" ✅`
                );
                cellMessages.push(
                  `&nbsp;&nbsp;&nbsp;&nbsp;Your output is correct.`
                );
              } else {
                cellMessages.push(
                  `&nbsp;&nbsp;&nbsp;&nbsp;Test case #${testCaseNumber}: Failed ❌`
                );
                cellMessages.push(
                  `Expected: \"${item?.output?.value}\", Found: \"${enteredOutput}\" ❌`
                );
                cellMessages.push(
                  `Feedback: ${
                    item?.output?.feedback_incorrect_output ||
                    'Your output is incorrect. Check your formula, references, and logic. Review the instructions and try again.'
                  }`
                );
              }
            }
            let updatedSubmissionData = submissionData;
            updatedSubmissionData[cellKey] = {
              enteredFormula: enteredFormula?.toString() || '',
              enteredOutput: enteredOutput?.toString() || '',
              weightage: Number(item?.mm || 0),
              score: Number(cellScore || 0)
            };
            setSubmissionData(updatedSubmissionData);

            totalScore += cellScore;
            tempMessages.push(...cellMessages, '<br>');
          }

          return cellMeta;
        }
      });
      hotInstance.render();

      if (check !== 'validate') return;

      // Detect repeating pattern
      const summarizedMessages = detectAndSummarizeRepetitions(tempMessages);
      let validCellsLength =
        Object?.keys(apiData?.validations || {})?.length || 1;
      let finalScore = totalScore / (totalCells / validCellsLength);

      if (excelActiveTab != 'Solution') {
        setMarks(finalScore);
      }
      // Generate cumulative summary after all cells are processed
      setTimeout(() => {
        let validCellsLength =
          Object?.keys(apiData?.validations || {})?.length || 1;
        let finalScore = totalScore / (totalCells / validCellsLength);

        let summaryMessages = [];
        if (finalScore === 100) {
          summaryMessages.push('All test cases passed! Excellent work! 🎉');
          summaryMessages.push(
            'Success! Your spreadsheet logic is on point! 🚀'
          );
          summaryMessages.push(`Final Score: 100/100 🏆`);
        } else {
          summaryMessages.push(
            'Some test cases failed. Review errors and try again. 🔁'
          );
          summaryMessages.push(
            `Final Score: ${finalScore.toFixed(2)}/100 Keep going! 🏆`
          );
        }

        // Update state with summarized messages
        setMessages([...summarizedMessages, ...summaryMessages]);
      }, 100);
    }
  };

  const convertToA1NotationForHashMap = (row, col) => {
    const columnIndexToLetter = (index) => {
      let letter = '';
      while (index >= 0) {
        letter = String.fromCharCode((index % 26) + 65) + letter;
        index = Math.floor(index / 26) - 1;
      }
      return letter;
    };
    return `${columnIndexToLetter(col)}${row + 1}`;
  };

  useEffect(() => {
    handleGeneratePivot('initial-validate');
    document?.documentElement?.setAttribute('excel-theme', 'editing');
  }, [attemptData]);

  useEffect(() => {
    let newCellHash = {};
    let formattedPivotData = [];

    tableDataForPivot?.forEach((row, rowIndex) => {
      let rowObj = {};
      Object.values(row)?.forEach((cell, colIndex) => {
        const cellRef = convertToA1NotationForHashMap(rowIndex, colIndex);
        newCellHash[cellRef] = cell || '';
        if (colIndex < apiData?.data?.dimensions?.columns) {
          let alphabet = String.fromCharCode((colIndex % 26) + 65);
          rowObj[alphabet] = cell || '';
        }
      });

      formattedPivotData.push(rowObj);
    });
    if (cellHash) {
      setCellHash(newCellHash);
    }
    if (isPivotOpen) {
      setTableDataForPivot(formattedPivotData);
    }
  }, [tableData, isPivotOpen]);

  const handleGeneratePivot = (type = '') => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      const hfEngine = hotInstance.getPlugin('formulas').engine;
      const sheetId = hfEngine.getSheetId(
        hotInstance.getPlugin('formulas').sheetName
      );
      const updatedData = hotInstance.getData().map((row, rowIndex) =>
        row.map(
          (cell, colIndex) =>
            hfEngine.getCellFormula({
              sheet: sheetId,
              row: rowIndex,
              col: colIndex
            }) ||
            hfEngine.getCellValue({
              sheet: sheetId,
              row: rowIndex,
              col: colIndex
            })
        )
      );
      const updatedDataForPivot = hotInstance.getData().map((row, rowIndex) =>
        row.map((cell, colIndex) =>
          hfEngine.getCellValue({
            sheet: sheetId,
            row: rowIndex,
            col: colIndex
          })
        )
      );
      setTableData(updatedData);
      setTableDataForPivot(updatedDataForPivot);
      hotInstance.loadData([...updatedData]);
      hotInstance.render();

      if (type == 'validate' || type == 'initial-validate') {
        applyCellStyles(updatedData, updatedDataForPivot, type);
      }

      if (type == 'solution') {
        console.log('Applying styles for Solution Tab...');
        applyCellStyles(updatedData, updatedData, 'solution');
      }
    }
  };

  const clearFilters = () => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;

      // Preserve user-inputted values in yellow-highlighted cells
      const data = hotInstance.getData();
      const cellMeta = hotInstance.getCellsMeta();

      const preservedValues = {};
      cellMeta.forEach((meta) => {
        if (meta) {
          const { row, col, className } = meta;
          if (className && className.includes('yellow-cell')) {
            preservedValues[`${row}-${col}`] = data[row][col];
          }
        }
      });
      hotInstance.getPlugin('filters').clearConditions();
      hotInstance.getPlugin('filters').filter();
      Object.keys(preservedValues).forEach((key) => {
        const [row, col] = key.split('-').map(Number);
        hotInstance.setDataAtCell(row, col, preservedValues[key]);
      });

      hotInstance.render();
      setIsFilterApplied(false);
      handleGeneratePivot();
    }
  };

  const handleTogglePivot = () => {
    setIsPivotOpen((prev) => !prev);
  };

  const handleTabClick = (tabName) => {
    if (!hotRef.current) return;
    const hotInstance = hotRef.current.hotInstance;
    setMessages([]);
    setStoredMessages([]);

    if (tabName === 'Solution') {
      ///////////////////////////////////////////////////////////////////////////////////////////
      console.log('Switching to Solution');

      // localStorage.setItem(
      //   'userSheetData',
      //   JSON.stringify(hotInstance.getData())
      // );
      setTableData([...apiData?.data?.cellData]);
      handleGeneratePivot('solution');

      hotInstance.updateSettings({
        cells: (row, col) => {
          const cellMeta = {};
          let colAlpha = String.fromCharCode((col % 26) + 65);
          let cellKey = `${colAlpha}${row + 1}`;
          let item = apiData?.validations[cellKey];

          if (item?.output?.value) {
            cellMeta.className = 'green-highlight';
            cellMeta.readOnly = true;
            cellMeta.renderer = (
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              const isSelected =
                instance.getSelected() &&
                instance
                  .getSelected()
                  .some(
                    (selection) => selection[0] === row && selection[1] === col
                  );
              let bestFormulaObj = item?.formula?.options?.find(
                (opt) => opt.isBest
              );
              let bestFormula = bestFormulaObj ? bestFormulaObj.value : '';
              let correctOutput = item?.output?.value || '';
              td.textContent = isSelected ? bestFormula : correctOutput;
              td.classList.add('green-highlight');
            };
          } else {
            cellMeta.readOnly = true;
          }

          return cellMeta;
        }
      });
      // hotInstance.addHook('afterSelectionEnd', (row, col) => {
      //   let colAlpha = String.fromCharCode((col % 26) + 65);
      //   let cellKey = `${colAlpha}${row + 1}`;
      //   let item = apiData?.validations[cellKey];

      //   if (item?.formula?.value) {
      //     setFormulaBarText(item.formula.value);
      //   } else {
      //     setFormulaBarText(hotInstance.getDataAtCell(row, col) || '');
      //   }
      // });
      hotInstance.render();
    } else if (tabName === 'New Sheet') {
      document?.documentElement?.setAttribute('excel-theme', 'editing');
      const restoredData = JSON.parse(
        localStorage.getItem('userSheetData')
      ) || [...apiData?.data?.cellData, ...arr];

      hotInstance.loadData(restoredData);
      hotInstance.updateSettings({
        cells: (row, col) => {
          const cellMeta = {};
          let colAlpha = String.fromCharCode((col % 26) + 65);
          let cellKey = `${colAlpha}${row + 1}`;
          let item = apiData?.validations[cellKey];
          if (item) {
            cellMeta.className = 'red-highlight';
          }

          return cellMeta;
        }
      });

      hotInstance.render();
    } else if (tabName === 'Your Submission') {
      setTableData([...apiData?.data?.cellData]);
      handleGeneratePivot('validate');
      let validationMessages = [];
      let totalScore = 0;
      let maxScore = 0;

      Object.entries(attemptData?.data || {}).forEach(([cellKey, cell]) => {
        let score = cell.score || 0;
        let weightage = cell.weightage || 0;
        totalScore += score;
        maxScore += weightage;

        validationMessages.push(
          `📊 ${cellKey}: Entered Formula: "${cell.enteredFormula}", ` +
            `Entered Output: "${cell.enteredOutput}" — Score: ${score}/${weightage}`
        );
      });

      // Append Overall Score
      validationMessages.push(
        `\n🏆 Overall Score: ${totalScore}/${maxScore} (${(
          (totalScore / maxScore) *
          100
        ).toFixed(2)}%)`
      );

      setMessages(validationMessages);
      setStoredMessages(validationMessages);
      setActiveTab('console');
      setShouldAnimate(!visitedOnce); //
      setVisitedOnce(true);
      setMarks(totalScore);
      let newSheetData = JSON.parse(JSON.stringify(apiData?.data?.cellData));

      Object.keys(attemptData?.data || {}).forEach((cellKey) => {
        let row = parseInt(cellKey.match(/\d+/)[0]) - 1;
        let col = cellKey.charCodeAt(0) - 65;

        let userEntry = attemptData.data[cellKey];

        if (newSheetData[row] && newSheetData[row][col] !== undefined) {
          newSheetData[row][col] = userEntry.enteredOutput;
        }
      });

      hotInstance.loadData(newSheetData);

      hotInstance.updateSettings({
        cells: (row, col) => {
          const cellMeta = {};
          let colAlpha = String.fromCharCode((col % 26) + 65);
          let cellKey = `${colAlpha}${row + 1}`;
          let userEntry = attemptData?.data?.[cellKey];
          let correctItem = apiData?.validations?.[cellKey];
          if (userEntry && correctItem) {
            let validationResult = validateFormulaAndOutput(
              userEntry.enteredFormula,
              userEntry.enteredOutput,
              correctItem
            );

            cellMeta.className = validationResult.className;

            // Ensure the user's formula/output appears in the cell
            cellMeta.renderer = (
              instance,
              td,
              row,
              col,
              prop,
              value,
              cellProperties
            ) => {
              const isSelected = instance
                .getSelected()
                ?.some(
                  (selection) => selection[0] === row && selection[1] === col
                );

              let displayFormula = isSelected
                ? userEntry.enteredFormula || ''
                : '';
              let displayOutput = userEntry.enteredOutput || '';

              td.textContent = displayFormula || displayOutput;
              td.classList.add(cellMeta.className);
            };

            cellMeta.readOnly = true;
          }

          return cellMeta;
        }
      });

      // hotInstance.addHook('afterSelectionEnd', (row, col) => {
      //   let colAlpha = String.fromCharCode((col % 26) + 65);
      //   let cellKey = `${colAlpha}${row + 1}`;
      //   let item = attemptData?.data?.[cellKey];

      //   if (item?.enteredFormula) {
      //     setFormulaBarText(item.enteredFormula);
      //   } else {
      //     setFormulaBarText(hotInstance.getDataAtCell(row, col) || '');
      //   }
      // });

      hotInstance.render();
    }

    setExcelActiveTab(tabName);
  };

  const handleRunClick = () => {
    if (excelActiveTab === 'Solution') {
      console.log('Running in Solution Mode...');

      let correctMessages = [];
      const validations = apiData?.validations;

      Object.entries(validations).forEach(([cellKey, validation]) => {
        let bestFormulaObj = validation?.formula?.options?.find(
          (opt) => opt.isBest
        );
        let bestFormula = bestFormulaObj
          ? bestFormulaObj.value
          : validation?.formula?.value || '';

        let correctValue = validation?.output?.value || '';

        if (validation?.formula?.flag && validation?.output?.flag) {
          correctMessages.push(
            `✅ ${cellKey}: Best Formula: "${bestFormula}", Output: "${correctValue}"`
          );
        } else if (validation?.formula?.flag) {
          correctMessages.push(
            `✅ ${cellKey}: Formula is correct: "${bestFormula}"`
          );
        } else if (validation?.output?.flag) {
          correctMessages.push(
            `✅ ${cellKey}: Output is correct: "${correctValue}"`
          );
        }
      });

      // console.log(correctMessages);
      setMessages(correctMessages);
      setStoredMessages(correctMessages);
      setActiveTab('console');
      setShouldAnimate(true);
    } else {
      // Run normal validation
      handleGeneratePivot('validate');
      setRunClicked(true);
      setStoredMessages(messages);
      setShouldAnimate(true);
      setActiveTab('console');
    }
  };

  let Submission = true;
  const [startTour, setStartTour] = useState(false);
  const [tourType, setTourType] = useState('');

  useEffect(() => {
    const hasSeenTour = localStorage.getItem('hasSeenTour');
    const hasSeenTabTour = localStorage.getItem('hasSeenTabTour');

    // ✅ Use `attemptData` to determine submission status
    let Submission = attemptData ? true : false;

    if (!hasSeenTour && !Submission) {
      setTourType('preSubmission');
      setStartTour(true);
    } else if (!hasSeenTabTour && Submission) {
      setTourType('postSubmission');
      setStartTour(true);
    } else {
      console.log('⛔ No Tour Started.');
    }
  }, [attemptData]);

  const preSubmissionSteps = [
    {
      element: '.problem-statement-area__sticky-header',
      intro: 'This section provides the problem details and instructions.'
    },
    {
      element: '.clear-button',
      intro: 'Click here to remove all applied filters.'
    },
    {
      element: '.run-button',
      intro: 'Press this button to execute your solution.'
    },
    {
      element: '.submit-button',
      intro: 'Click here to submit your work when finished.'
    },
    {
      element: '.pivot-table-tab',
      intro: 'Click to open the Pivot Table view.',
      position: 'bottom'
    },
    {
      element: '.console-tab',
      intro: 'Click to view the Console output.',
      position: 'bottom'
    }
  ];

  const postSubmissionSteps = [
    {
      element: '.new-sheet-tab',
      intro: "You're now in the 'New Sheet' tab!",
      position: 'bottom'
    },
    {
      element: '.solution-tab',
      intro: 'This is where you can check the solution!',
      position: 'bottom'
    },
    {
      element: '.your-submission-tab',
      intro: 'Check your submission here!',
      position: 'bottom'
    },
    {
      element: '.clear-button',
      intro: 'Click here to remove all applied filters.',
      position: 'bottom'
    },
    {
      element: '.run-button',
      intro: 'Press this button to execute your solution.',
      position: 'bottom'
    },
    {
      element: '.pivot-table-tab',
      intro: 'Click to open the Pivot Table view.',
      position: 'bottom'
    },
    {
      element: '.console-tab',
      intro: 'Click to view the Console output.',
      position: 'bottom'
    }
  ];

  return (
    <>
      {startTour && (
        <GuidedTour
          steps={
            tourType === 'preSubmission'
              ? preSubmissionSteps
              : postSubmissionSteps
          }
          tourType={tourType}
          onTourEnd={() => setStartTour(false)}
        />
      )}
      <SplitPane
        split='horizontal'
        defaultSize={400}
        minSize={100}
        maxSize='100vh'
      >
        <div className='handson-table-section'>
          <div
            className={`handson-table-section__excel-sandbox ${
              Submission ? 'not-tabs' : ''
            }`}
          >
            <ExcelTabSelection
              isFilterApplied={isFilterApplied}
              activeTab={activeTab}
              runClicked={runClicked}
              setRunClicked={setRunClicked}
              setStoredMessages={setStoredMessages}
              shouldAnimate={shouldAnimate}
              setShouldAnimate={setShouldAnimate}
              clearFilters={clearFilters}
              excelActiveTab={excelActiveTab}
              handleTabClick={handleTabClick}
              handleRunClick={handleRunClick}
              attemptData={attemptData}
              submissionData={submissionData}
            />
          </div>
          <FormulaBar
            cellReference={cellReference}
            formulaBarText={formulaBarText}
            setFormulaBarText={setFormulaBarText}
            hotRef={hotRef}
            validations={apiData?.validations}
            excelActiveTab={excelActiveTab}
          />
          <Temp
            hotSettings={hotSettings}
            hotRef={hotRef}
            setTableData={setTableData}
            setCellReference={setCellReference}
            setFormulaBarText={setFormulaBarText}
            setMessages={setMessages}
            excelActiveTab={excelActiveTab}
            apiData={apiData}
            attemptData={attemptData}
          />
        </div>
        <>
          {messages.length > 0 && excelActiveTab != 'Solution' && (
            <div
              className='submission-report'
              style={{
                background: `url(${
                  theme == 'light' ? backgroundExcel : darkBackground
                })`,
                backgroundSize: '100%, 100%, contain'
              }}
            >
              <div className='submission-report__score'>
                Final Score:{' '}
                <span className='submission-report__total-score'>
                  {marks}/100
                </span>
              </div>
            </div>
          )}
          <OutputTerminal
            pivotData={tableDataForPivot}
            messages={messages}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            storedMessages={storedMessages}
            shouldAnimate={shouldAnimate}
            setShouldAnimate={setShouldAnimate}
            setPivotConfig={setPivotConfig}
            pivotConfig={pivotConfig}
            handleTogglePivot={handleTogglePivot}
          />
        </>
      </SplitPane>
    </>
  );
}

export default HandsonTableParent;
