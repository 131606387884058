import React from 'react';
import './VRiseCard.scss';
import { useNavigate } from 'react-router-dom';
import companyFallbackImage from '../../asset/images/company_fallback_image.png';

const icon_mapping = {
  location: 'location_on',
  type: 'trip',
  salary: 'currency_rupee'
};

const ArrowIndicator = ({ isActive }) => {
  if (!isActive) return null;

  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        right: '-24px',
        transform: 'translateY(-50%)',
        width: '24px',
        height: '24px',
        zIndex: 10
      }}
    >
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='11'
        height='16'
        viewBox='0 0 11 16'
        fill='none'
      >
        <path
          d='M9.50385 9.6641C10.6913 8.87246 10.6913 7.12754 9.50385 6.3359L0 0L0 16L9.50385 9.6641Z'
          fill='#5095FC'
        />
      </svg>
    </div>
  );
};

export default function VRiseCard({
  opportunity,
  setActiveJdId,
  activeJdId,
  activeTab
}) {
  const navigate = useNavigate();
  return (
    <div
      className={`vrise-card-container ${
        opportunity.job_id === activeJdId
          ? 'vrise-card-active'
          : 'vrise-card-inactive'
      } `}
      style={{ cursor: 'pointer', position: 'relative' }}
      onClick={() => setActiveJdId(opportunity.job_id)}
    >
      <div className='vrise-card-designation'>
        <img
          src={companyFallbackImage}
          width={30}
          height={30}
          alt='company-logo'
        />
        <div className='vrise-card-designation-info'>
          <div className='vrise-card-designation-title'>
            {opportunity.job_title}
          </div>
          <div className='vrise-card-designation-company'>
            {opportunity.company}
          </div>
        </div>
      </div>
      <div className='vrise-card-info'>
        {Object.keys(opportunity.job_details).map((detail) => (
          <div className='vrise-card-info-container'>
            <div className='vrise-card-info-options'>
              <span
                class='material-symbols-outlined'
                style={{ fontSize: '18px' }}
              >
                {icon_mapping[detail]}
              </span>
              <div className='vrise-card-info-placeholder'>
                {detail.charAt(0).toUpperCase() + detail.slice(1)}
              </div>
            </div>

            <div className='vrise-card-info-data'>
              {opportunity.job_details[detail]}
            </div>
          </div>
        ))}
      </div>
      <div
        className='vrise-card-applyBy'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <div>Apply by: {opportunity.resp_req_by_date}</div>
        <div className='tab-view-only'>
          {activeTab != 'Missed' && (
            <div
              className='vrise-job-view-header-cta-applynow'
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate(`/vrise/${opportunity.hash2}`);
              }}
            >
              Apply Now
            </div>
          )}
          <a
            className='vrise-job-view-header-cta-jd'
            href={opportunity.jd_link}
            target='_blank'
          >
            View JD
          </a>
        </div>
      </div>
      <ArrowIndicator isActive={opportunity.job_id === activeJdId} />
    </div>
  );
}
