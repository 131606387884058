import React, { useEffect, useState } from 'react';
import sheets from '../../asset/images/sheets.svg';
import './excelTabSelection.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  createMoodleAttempt,
  createProblemSubmission
} from '../../redux/feature/handsonSlice';
import { v4 as uuidv4 } from 'uuid';
import { ClipLoader } from 'react-spinners';

function ExcelTabSelection({
  isFilterApplied,
  activeTab,
  runClicked,
  setRunClicked,
  setStoredMessages,
  shouldAnimate,
  setShouldAnimate,
  clearFilters,
  excelActiveTab,
  handleTabClick,
  handleRunClick,
  attemptData = null,
  submissionData = null
}) {
  let Submission = attemptData ? true : false;
  const userid = useSelector((state) => state?.auth?.userData?.userid);
  const {
    problemSetData,
    isCreateProblemSubmissionLoading,
    isFetchProblemSubmissionLoading,
    isCreateMoodleAttemptLoading
  } = useSelector((obj) => obj?.handsonSlice);

  // const handleRunClick = () => {
  //   handleGeneratePivot('validate');
  //   setRunClicked(true);
  //   setStoredMessages(messages);
  //   setShouldAnimate(true);
  //   setActiveTab('console');
  // };
  const dispatch = useDispatch();
  const { moodleProblemDetails } = useSelector((state) => state?.handsonSlice);
  const handleSubmit = () => {
    // Hardcoded submission payload
    const submissionPayload = {
      problemUserId: `${problemSetData?._id}_${userid}`,
      // problemUserId: `67c187634b7c8967c177514a_10338`,
      problemType: 'Excel',
      attempts: [
        {
          attemptId: uuidv4(),
          duration: null,
          weightage: 100,
          overallScore: Object?.values(submissionData || {})?.reduce(
            (sum, { score }) => sum + score,
            0
          ),
          submittedAt: new Date().toLocaleString('en-IN', {
            timeZone: 'Asia/Kolkata'
          }),
          data: submissionData
        }
      ]
    };

    const submissionPayloadMoodle = {
      handsonid: moodleProblemDetails?.id,
      testid: moodleProblemDetails?.testid,
      userid: userid,
      timestart: Date.now(),
      timeend: Date.now(),
      time_taken: 0,
      attempt: Date.now(),
      max_score: 100,
      total_score: Object?.values(submissionData || {})?.reduce(
        (sum, { score }) => sum + score,
        0
      )
    };

    console.log('Submitting hardcoded problem data:', submissionPayload);

    // Dispatch the request with the hardcoded data
    dispatch(createProblemSubmission(submissionPayload));
    dispatch(createMoodleAttempt(submissionPayloadMoodle));
  };

  useEffect(() => {
    if (!isFilterApplied) {
      setRunClicked(false);
      setStoredMessages([]);
    }
  }, [isFilterApplied]);
  useEffect(() => {
    if (activeTab === 'console' && !shouldAnimate) {
      setShouldAnimate(false);
    }
  }, [activeTab]);
  return (
    <>
     {/* {!Submission && <GuidedTour steps={preSubmissionSteps} tourType="preSubmission" onTourEnd={() => {}} />}
     {Submission && <GuidedTour steps={postSubmissionSteps} tourType="postSubmission" onTourEnd={() => {}} />} */}
      {!Submission ? (
        <div className='excel-tab-header'>
          <img src={sheets} alt='Excel' />
          Excel Sandbox
        </div>
      ) : (
        <div className='excel-tab-section'>
          <div
            className={`excel-tab-section__new-sheet new-sheet-tab ${
              excelActiveTab === 'New Sheet' ? 'active' : ''
            }`}
            onClick={() => handleTabClick('New Sheet')}
          >
            New Sheet
          </div>
          <div
            className={`excel-tab-section__new-sheet solution-tab ${
              excelActiveTab === 'Solution' ? ' active' : ''
            }`}
            onClick={() => handleTabClick('Solution')}
          >
            Solution
          </div>
          <div
            className={`excel-tab-section__new-sheet your-submission-tab ${
              excelActiveTab === 'Your Submission' ? ' active' : ''
            }`}
            onClick={() => handleTabClick('Your Submission')}
          >
            Your Submission
          </div>
        </div>
      )}

      <div className='excel-tab-header__action-buttons'>
        <div
          className={`excel-tab-header__clear clear-button ${
            isFilterApplied ? '' : ' disabled'
          }`}
          onClick={isFilterApplied ? clearFilters : null}
        >
          <span class='material-symbols-outlined'>mop</span>
        </div>
        {excelActiveTab !== 'Your Submission' && (
          <div
            className={`excel-tab-header__run run-button${
              isFilterApplied ? ' disabled' : ''
            }`}
            onClick={ isFilterApplied ? null : handleRunClick}
          >
            Run
            <span className='material-symbols-outlined'>play_arrow</span>
          </div>
        )}

        {!Submission && (
          <div
            className={`excel-tab-header__submit submit-button ${
              runClicked ? '' : ' disabled'
            }`}
            onClick={() => (runClicked ? handleSubmit() : null)}
            style={{
              cursor: runClicked ? 'pointer' : 'not-allowed'
            }}
          >
            {isFetchProblemSubmissionLoading ||
            isCreateProblemSubmissionLoading || isCreateMoodleAttemptLoading? (
              <ClipLoader color='var(--primary-surface-color)' size={20} />
            ) : (
              'Submit'
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ExcelTabSelection;
