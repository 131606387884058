import React, { useEffect } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import { registerAllModules } from 'handsontable/registry';
import 'react-pivottable/pivottable.css';
import HyperFormula from 'hyperformula';
import './temp.scss';

registerAllModules();

function Temp({
  hotSettings,
  hotRef,
  setCellReference,
  setFormulaBarText,
  setMessages,
  attemptData,
  excelActiveTab,
  apiData
}) {
  // const hotRef = useRef(null);
  let rowg = null;
  let colg = null;
  let formulaText = '';
  let temp = false;
  let godEditingMode = false;

  function isBalancedParentheses(str) {
    const stack = [];
    const pairs = {
      ')': '(',
      ']': '[',
      '}': '{'
    };

    for (let char of str) {
      if (char === '(' || char === '[' || char === '{') {
        stack.push(char);
      } else if (char === ')' || char === ']' || char === '}') {
        if (stack.length === 0 || stack.pop() !== pairs[char]) {
          return false;
        }
      }
    }

    return stack.length === 0;
  }

  const convertToA1Notation = (row, col, row2, col2) => {
    if (row == -1 && col == -1) {
      return;
    } else if (row == -2 || col == -2) {
      return;
    } else if (col == -1) {
      return `${row + 1}:${row + 1}`;
    } else if (row == -1) {
      return `${String.fromCharCode((col % 26) + 65)}:${String.fromCharCode(
        (col % 26) + 65
      )}`;
    }
    const columnIndexToLetter = (index) => {
      let letter = '';
      while (index >= 0) {
        letter = String.fromCharCode((index % 26) + 65) + letter;
        index = Math.floor(index / 26) - 1;
      }
      return letter;
    };

    const startCell = `${columnIndexToLetter(col)}${row + 1}`;
    const endCell = `${columnIndexToLetter(col2)}${row2 + 1}`;
    return startCell == endCell ? startCell : `${startCell}:${endCell}`;
  };

  useEffect(() => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      const hfEngine = hotInstance.getPlugin('formulas').engine;

      hotInstance.removeHook('afterSelectionEnd'); // todo: remove in future if this causes an issue

      hotInstance.addHook('beforeKeyDown', (event) => {
        if (godEditingMode) {
          const validKeys = /^[a-zA-Z0-9!@#$%^&*()_+={}\[\]:;"'<>,.?/\\|-]$/; // Allow letters, numbers, symbols
          const specialKeys = [
            'Shift',
            'Control',
            'CapsLock',
            'Alt',
            'Tab',
            'Meta',
            'Escape',
            'ArrowUp',
            'ArrowDown',
            'ArrowLeft',
            'ArrowRight',
            'Enter',
            'Backspace',
            'Delete'
          ];

          if (!specialKeys.includes(event.key) && validKeys.test(event.key)) {
            const existingValue = hotInstance.getDataAtCell(rowg, colg) || ''; // Get existing value
            const newValue = existingValue + event.key; // Append new character

            hotInstance.setDataAtCell(rowg, colg, newValue); // Update the cell value
            console.log(`Updated Cell [${rowg}, ${colg}]:`, newValue);
          } else if(event.key == "Backspace") {
            const existingValue = hotInstance.getDataAtCell(rowg, colg) || ''; // Get existing value
            const newValue = existingValue.slice(0, existingValue.length - 1); // Append new character
            hotInstance.setDataAtCell(rowg, colg, newValue); // Update the cell value
            console.log(`Updated Cell [${rowg}, ${colg}]:`, newValue);
          }
        }
      });

      hotInstance.addHook('afterSelectionEnd', (row1, col1, row2, col2) => {
        const sheetId = hfEngine.getSheetId(
          hotInstance.getPlugin('formulas').sheetName
        );
        let colAlpha = String.fromCharCode(65 + (col1 % 26));
        let cellKey = `${colAlpha}${row1 + 1}`;
        let result = '';

        if (excelActiveTab === 'Solution') {
          let item = apiData?.validations?.[cellKey];
          if (item?.formula?.options) {
            let bestFormulaObj = item.formula.options.find((opt) => opt.isBest);
            let bestFormula = bestFormulaObj ? bestFormulaObj.value : '';
            result = bestFormula || item?.formula?.value || '';
          } else {
            result = hotInstance.getDataAtCell(row1, col1) || '';
          }
        } else if (excelActiveTab === 'Your Submission') {
          let item = attemptData?.data?.[cellKey];
          if (item?.enteredFormula) {
            result = item.enteredFormula;
          } else {
            result = hotInstance.getDataAtCell(row1, col1) || '';
          }
        } else {
          result =
            hfEngine.getCellFormula({ sheet: sheetId, row: row1, col: col1 }) ||
            hfEngine.getCellValue({ sheet: sheetId, row: row1, col: col1 }) ||
            '';
        }
        setFormulaBarText(result || '');
        setCellReference(convertToA1Notation(row1, col1, row2, col2));
        if (row1 === rowg && col1 === colg) return;
        const range = convertToA1Notation(row1, col1, row2, col2);
        const newValue = `${formulaText} ${range}`;
        if (rowg !== 0 && colg !== 0 && (!rowg || !colg)) return;

        temp = true;
        hotInstance.setDataAtCell(rowg, colg, newValue);
      });

      hotInstance.addHook('afterBeginEditing', (row, col) => {
        godEditingMode = false;

        setMessages([]);
        document?.documentElement?.setAttribute('excel-theme', 'editing'); //yahan
        if (temp) {
          temp = false;
          return;
        }
        formulaText = '';
        colg = null;
        rowg = null;
        const editor = hotInstance.getActiveEditor();

        if (editor) {
          const editorValue = editor.getValue();
          const regex = /^'=.*[^)]$/;
          if (regex.test(editorValue)) {
            const updatedValue = editorValue.slice(1);
            editor.setValue(updatedValue);
          }
        }
      });

      hotInstance.addHook('afterChange', (changes, source) => {
        if (temp) {
          temp = false;
          return;
        }

        if (source === 'edit' && changes) {
          changes.forEach(([row, col, oldValue, newValue]) => {
            const regex = /^=.*[^)]$/;
            const sheetId = hfEngine.getSheetId(
              hotInstance.getPlugin('formulas').sheetName
            );
            const result = hfEngine.getCellValue({ sheet: sheetId, row, col });
            const formulaErrors = [
              '#ERROR!',
              '#DIV/0!',
              '#VALUE!',
              '#NAME?',
              '#REF!',
              '#N/A',
              '#NUM!',
              '#NULL!'
            ];

            let isBalanced = isBalancedParentheses(newValue);
            if (
              (regex.test(newValue) || newValue == '=') &&
              (!isBalanced || formulaErrors.includes(result?.value))
            ) {
              // Update the value by prepending "'"
              const updatedValue = `'${newValue}`;
              hotInstance.setDataAtCell(row, col, updatedValue);
              rowg = row;
              colg = col;
              formulaText = updatedValue;
              godEditingMode = true;
            } else if (
              !regex.test(newValue) &&
              newValue != '=' &&
              isBalanced &&
              !formulaErrors.includes(result?.value)
            ) {
              rowg = null;
              colg = null;
              godEditingMode = false;
            }
          });
        }
      });

      if (hotInstance) {
        hotInstance.validateCells();
      }
    }
  }, [excelActiveTab]);

  // const hfInstance = HyperFormula.buildEmpty();
  return (
    <div
      style={{ width: '100%', height: 'calc(100% - 22px)', overflow: 'auto' }}
      className='handsontable-main-section'
    >
      <HotTable ref={hotRef} settings={hotSettings} />
    </div>
  );
}

export default Temp;
