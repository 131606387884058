import React, { useEffect, useState } from 'react';
import './formulaBar.scss';
import { Tooltip } from 'react-tooltip';

function FormulaBar({ 
  cellReference, 
  formulaBarText, 
  setFormulaBarText, 
  hotRef, 
  validations, 
  excelActiveTab 
}) {
  const [currentCell, setCurrentCell] = useState(cellReference || 'A1');
  const [inputValue, setInputValue] = useState(formulaBarText || '');
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setCurrentCell(cellReference || 'A1');
  }, [cellReference]);

  useEffect(() => {
    setInputValue(formulaBarText || '');

    // Condition 1: Check if the current cell is in validations
    const isCellValid = cellReference && validations?.[cellReference];

    // Condition 2: Make read-only if active tab is "Solution" or "Your Submission"
    const isTabReadOnly = excelActiveTab === "Solution" || excelActiveTab === "Your Submission";

    setIsEditable(!!isCellValid && !isTabReadOnly);
  }, [formulaBarText, cellReference, validations, excelActiveTab]);

  const handleInputChange = (e) => {
    if (isEditable) {
      setInputValue(e.target.value);
    }
  };

  const handleInputBlur = () => {
    if (isEditable) {
      updateCellValue(inputValue);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isEditable) {
      updateCellValue(inputValue);
    }
  };

  const updateCellValue = (newValue) => {
    if (!hotRef.current) return;

    const hotInstance = hotRef.current.hotInstance;
    if (!hotInstance) return;

    const [row, col] = parseCellReference(currentCell);
    if (row === null || col === null) return;

    hotInstance.setDataAtCell(row, col, newValue);
    setFormulaBarText(newValue);

    // Ensure Handsontable updates the UI
    hotInstance.render();
  };

  const parseCellReference = (ref) => {
    const match = ref.match(/^([A-Z]+)(\d+)$/);
    if (!match) return [null, null];

    const col = match[1]
      .split('')
      .reduce((acc, char) => acc * 26 + (char.charCodeAt(0) - 65), 0);
    const row = parseInt(match[2], 10) - 1;

    return [row, col];
  };

  return (
    <div className='formula-bar'>
      <div className='cell-reference'>
        <span>{currentCell}</span>
      </div>
      <div
        data-tooltip-id='formulabar-tooltip'
        data-tooltip-content={
          isEditable ? '' : 'Read-only cell'
        }
        data-tooltip-place='top'
        className={`formula-input ${isEditable ? 'editable' : 'readonly'}`}
      >
        <span className='fx-icon'>fx</span>
        <input
          type='text'
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputBlur}
          onKeyDown={handleKeyDown}
          readOnly={!isEditable} // Read-only if not editable
        />
        <Tooltip id='formulabar-tooltip' />
      </div>
    </div>
  );
}

export default FormulaBar;
