import React, { useEffect, useState } from 'react';
import ConsoleOutput from '../ConsoleOutput/ConsoleOutput';
import PivotTableUI from 'react-pivottable/PivotTableUI';
// import 'react-pivottable/pivottable.css';
import './outputTerminal.scss';

function OutputTerminal({
  messages,
  pivotData,
  setPivotConfig,
  pivotConfig,
  handleTogglePivot,
  setActiveTab,
  activeTab,
  storedMessages,
  shouldAnimate,
  setShouldAnimate
}) {

  const handlePivotTableClick = () => {
    handleTogglePivot();
    setActiveTab('pivot');
  };

  return (
    <>
      <div className='output-terminal'>
        <div className='output-terminal-header-section'>
          <div
            className={`output-terminal__pivot-table pivot-table-tab ${
              activeTab === 'pivot' ? 'active' : ''
            }`}
            onClick={handlePivotTableClick}
          >
            Pivot Table
          </div>
          <div
            className={`output-terminal__pivot-table console-tab ${
              activeTab === 'console' ? 'active' : ''
            }`}
            onClick={() => {
              setActiveTab('console');
              setShouldAnimate(false);
            }}
          >
            Console
          </div>
        </div>
        <div className='output-terminal-content'>
          {activeTab === 'console' ? (
            <ConsoleOutput
              messages={storedMessages.length > 0 ? storedMessages : messages}
              shouldAnimate={shouldAnimate}
            />
          ) : (
            pivotData.length > 0 && (
              <PivotTableUI
                data={pivotData}
                onChange={setPivotConfig}
                {...pivotConfig}
              />
            )
          )}
        </div>
      </div>
    </>
  );
}

export default OutputTerminal;
