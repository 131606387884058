import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import VRiseCard from '../../components/VRiseCard/VRiseCard';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCareerDashBoard,
  fetchJD,
  fetchJobByType
} from '../../redux/feature/vriseSlice';
import TabHeader from '../../components/TabSelector/TabHeader';
import { VRiseStatusCard } from '../../components/VRiseCard/VRiseStatusCard';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../config/routes';
import Pagination from '../../@common/Pagination/Pagination';
import companyFallbackImage from '../../asset/images/company_fallback_image.png';

const description_label = {
  about: 'About the Job',
  responsibility: 'Key Responsibilities'
};

const career_status = {
  Active: 'activeCount',
  Applied: 'appliedCount',
  Missed: 'sharedCount'
};

const cvStatusMap = {
  null: { cta: 'Not submitted', class: 'vrise-grey' },
  0: { cta: 'Pending', class: 'vrise-yellow' },
  1: { cta: 'Approved', class: 'vrise-green' },
  2: { cta: 'Not Approved', class: 'vrise-red' },
  3: { cta: 'Pending', class: 'vrise-yellow' },
  undefined: { cta: 'Not submitted', class: 'vrise-grey' }
};

const placedLearners = [
  'Jayjit Trivedi placed at <b>Homesfy</b>',
  'Sumit Roy placed at <b>Right Horizon</b>',
  'Vaibhav Kamble placed at <b>OTPless</b>',
  'Akhilesh Rao placed at <b>Asia Pacific</b>',
  'Akhil Sharma placed at <b>MyySports</b>',
  'Abhilasha Joshi placed at <b>Goldman Sachs (Experis)</b>',
  'Harsh Choudhary placed at <b>PAMM</b>',
  'Monish k placed at <b>PAMM</b>',
  'Laveena Maharshi placed at <b>PAMM</b>',
  'Mir Abdul Malique placed at <b>Tech mahindra</b>',
  'Mayank Singh placed at <b>EvilRage Esports</b>',
  'Mrinal Pandey placed at <b>Arth House</b>',
  'Parikshit Bulbule placed at <b>EvilRage Esports</b>',
  'Sukanya Buva placed at <b>EvilRage Esports</b>',
  'Ankur Saun Saun placed at <b>EvilRage Esports</b>'
];

export default function VRise() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useSelector((state) => state?.auth?.userData?.userid);
  const { loading, data: dashboardData } = useSelector(
    (state) => state?.vriseSlice
  );
  const userData = useSelector(
    (state) => state?.user?.profileData?.leanerApiData
  );
  const userImage = useSelector(
    (state) => state?.user?.profileData?.profileImg
  );
  const cvStatus = useSelector(
    (state) =>
      state?.user?.profileData?.leanerApiData?.workExperience?.resumeStatus
  );
  const { isLoading, data: missedOpportunityData } = useSelector(
    (state) => state?.vriseSlice?.fetchJobByType
  );
  const { isJDLoading, jdData } = useSelector(
    (state) => state?.vriseSlice?.fetchJD
  );

  const [activeJdId, setActiveJdId] = useState(null);
  const [activeTab, setActiveTab] = useState('Active');
  const [pageNo, setPageNo] = useState(1);

  let careerDashboardData = { ...dashboardData };

  if (activeTab != 'Active') {
    careerDashboardData = {
      ...careerDashboardData,
      activeData: missedOpportunityData
    };
  }

  // const uniqueArray = careerDashboardData?.activeData?.filter(
  //   (item, index, self) =>
  //     index === self.findIndex((t) => t.job_id === item.job_id)
  // );

  // careerDashboardData.activeData = uniqueArray;

  useEffect(() => {
    if (userId != null) {
      if (activeTab == 'Active')
        dispatch(
          fetchCareerDashBoard({
            userId
          })
        );
      else if (activeTab == 'Applied')
        dispatch(
          fetchJobByType({
            userId,
            type: 'active',
            offset: pageNo - 1
          })
        );
      else {
        dispatch(
          fetchJobByType({
            userId,
            type: activeTab,
            offset: pageNo - 1
          })
        );
      }
    }
  }, [userId, activeTab, pageNo]);

  useEffect(() => {
    if (careerDashboardData?.activeData?.[0]?.job_id && !activeJdId) {
      if (activeTab != 'Active' && isLoading) {
        setActiveJdId(missedOpportunityData[0].job_id);
      } else setActiveJdId(careerDashboardData.activeData[0].job_id);
    }
  }, [careerDashboardData]);

  useEffect(() => {
    if (activeJdId) {
      dispatch(
        fetchJD({
          userId,
          jdId: activeJdId
        })
      );
    }
  }, [activeJdId]);

  const stickyDivRef = useRef(null); // Ref for the sticky div
  const [isSticky, setIsSticky] = useState(false); // Track if the div is sticky
  const jobViewContainerRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      if (stickyDivRef.current && jobViewContainerRef.current) {
        const container = jobViewContainerRef.current;
        const stickyDiv = stickyDivRef.current;

        // Get the scroll position of the container
        const scrollTop = container.scrollTop;

        // Get the offset of the sticky div from the top of the container
        const stickyDivOffset = stickyDiv.offsetTop;

        // Check if the user has scrolled past the sticky div's position

        const isSticky = scrollTop >= 1;
        setIsSticky(isSticky);
      }
    };

    const container = jobViewContainerRef.current;
    if (container) {
      // Immediately check the scroll position on mount
      handleScroll();
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isJDLoading, activeJdId, jdData]); // Include dependencies

  return (
    <div className='vrise-container'>
      <div
        style={{
          overflowY: activeTab == 'Applied' ? 'auto' : 'hidden'
        }}
        className='vrise-job-section'
      >
        <div className='vrise-tabs'>
          {['Active', 'Applied', 'Missed'].map((data) => (
            <div
              className={`vrise-tab ${
                data === activeTab ? 'vrise-active-status' : ''
              }`}
              onClick={() => {
                setActiveTab(data);
                setActiveJdId(null);
                setPageNo(1);
              }}
              style={{ cursor: 'pointer' }}
            >
              <div className='vrise-tab-status'>{data}</div>
              <div className='vrise-tab-count'>
                {careerDashboardData[career_status[data]]}
              </div>
            </div>
          ))}
        </div>
        {activeTab == 'Applied' ? (
          careerDashboardData?.activeData.length != 0 ? (
            <div
              style={{
                display: 'flex',
                gap: '16px',
                flexDirection: 'column',
                width: '100%'
              }}
            >
              {careerDashboardData?.activeData?.map((data, index) => (
                <VRiseStatusCard
                  key={`${data.job_id + '_' + index}`}
                  data={data}
                />
              ))}
              {careerDashboardData[career_status[activeTab]] > 10 && (
                <div style={{ alignSelf: 'center' }}>
                  <Pagination
                    totalCount={careerDashboardData[career_status[activeTab]]}
                    itemsPerPage={10}
                    defaultPage={pageNo}
                    onPageChange={(page) => {
                      setPageNo(page);
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className='empty-container'>
              <div className='empty-container-section'>
                <img
                  src='https://staging.herovired.com/wp-content/uploads/2025/03/undraw_interview_yz52-1.png'
                  width={230}
                  height={180}
                  alt='dummy_pic'
                />
                <div className='empty-container-text'>
                  You haven't applied for any jobs yet. Explore opportunities
                  and take the next step!
                </div>
              </div>
            </div>
          )
        ) : null}

        {activeTab != 'Applied' ? (
          careerDashboardData?.activeData?.length ? (
            <div className='vrise-resume-container'>
              <div
                className='vrise-resume-cards'
                style={{
                  overflowY: 'auto',
                  height: 'calc(100vh - 210px)',
                  overflowX: 'hidden'
                }}
              >
                {careerDashboardData?.activeData?.map((opportunity, index) => (
                  <VRiseCard
                    key={`${opportunity.job_id + '_' + index + activeTab}`}
                    opportunity={opportunity}
                    setActiveJdId={setActiveJdId}
                    activeJdId={activeJdId}
                    activeTab={activeTab}
                  />
                ))}
                {careerDashboardData[career_status[activeTab]] > 10 && (
                  <div style={{ alignSelf: 'center' }}>
                    <Pagination
                      totalCount={careerDashboardData[career_status[activeTab]]}
                      itemsPerPage={10}
                      defaultPage={pageNo}
                      onPageChange={(page) => setPageNo(page)}
                    />
                  </div>
                )}
              </div>
              {!isJDLoading && (
                <div className='vrise-job-view' ref={jobViewContainerRef}>
                  <div className='vrise-experiment' ref={stickyDivRef}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px',
                        width: '100%'
                      }}
                    >
                      <img
                        src={companyFallbackImage}
                        width={36}
                        height={36}
                        alt='company-logo'
                      />
                      <div className='vrise-job-view-header-info-jobtitle'>
                        {jdData.company}
                      </div>
                      {isSticky && activeTab != 'Missed' && (
                        <div
                          className='vrise-job-view-header-cta-applynow'
                          style={{ cursor: 'pointer', marginLeft: 'auto' }}
                          onClick={() => {
                            navigate(`/vrise/${jdData.hash2}`);
                          }}
                        >
                          Apply Now
                        </div>
                      )}
                    </div>
                    <div className='vrise-job-view-header-info-company'>
                      {jdData.job_title}
                    </div>
                  </div>
                  <div className='vrise-job-view-header'>
                    <div className='vrise-job-view-header-info'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '16px',
                          alignSelf: 'stretch'
                        }}
                      >
                        {Object.keys(jdData?.job_details || [])?.map(
                          (detail) => (
                            <div className='vrise-job-view-header-info-details'>
                              {jdData?.job_details[detail]}
                            </div>
                          )
                        )}
                      </div>
                      <p className='vrise-job-view-header-tagline-text'>
                        Overall Exp: {jdData.min_exp} - {jdData.max_exp} years
                      </p>
                      <p className='vrise-job-view-header-tagline-text'>
                        Relevant Exp: {jdData.min_relevant_exp} -{' '}
                        {jdData.max_relevant_exp} years
                      </p>
                    </div>
                    <div className='vrise-job-view-header-cta'>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          gap: '12px'
                        }}
                      >
                        {activeTab != 'Missed' && !isSticky && (
                          <div
                            className='vrise-job-view-header-cta-applynow'
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              navigate(`/vrise/${jdData.hash2}`);
                            }}
                          >
                            Apply Now
                          </div>
                        )}
                        <a
                          className='vrise-job-view-header-cta-jd'
                          href={jdData.jd_link}
                          target='_blank'
                        >
                          View JD
                        </a>
                      </div>
                      <div className='vrise-job-view-header-tagline'>
                        <span
                          class='material-symbols-outlined'
                          style={{ color: '#5095FC' }}
                        >
                          hotel_class
                        </span>
                        <p className='vrise-job-view-header-tagline-text'>
                          Recruiters seek 100% profiles - complete yours to
                          stand out!
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='vrise-job-view-description'>
                    {Object.keys(jdData?.description || [])?.map((data) => {
                      return (
                        <div className='vrise-job-about'>
                          <div className='vrise-job-about-title'>
                            {description_label[data]}
                          </div>
                          <div
                            className='vrise-job-about-description'
                            dangerouslySetInnerHTML={{
                              __html: jdData?.description[data]
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className='empty-container'>
              <div className='empty-container-section'>
                <img
                  src='https://staging.herovired.com/wp-content/uploads/2025/03/undraw_interview_yz52-1.png'
                  width={230}
                  height={180}
                  alt='dummy_pic'
                />
                <div className='empty-container-text'>
                  {activeTab == 'Missed'
                    ? "Great job! You haven't missed any opportunities. Stay proactive and apply on time!"
                    : 'No active job opportunities right now. Keep learning and check back soon!'}
                </div>
              </div>
            </div>
          )
        ) : null}
      </div>
      <div className='vrise-user-profile-section'>
        <div className='vrise-user-profile-container'>
          <div className='vrise-user-profile-data'>
            <img
              src={userImage}
              width={76}
              height={76}
              style={{ borderRadius: '50%' }}
            />
            <div className='vrise-user-profile-name'>
              {userData?.firstName} {userData?.lastName}
            </div>
          </div>
          <div
            style={{
              background: 'var(--border-color)',
              width: '100%',
              height: '1px'
            }}
          />
          <div className='vrise-user-profile-resume-status'>
            <p className='vrise-user-profile-status'>Resume Status</p>
            <div
              className={`vrise-user-profile-tags ${cvStatusMap?.[cvStatus]?.class}`}
            >
              {cvStatusMap?.[cvStatus]?.cta}
            </div>
          </div>
          <div
            style={{
              background: 'var(--border-color)',
              width: '100%',
              height: '1px'
            }}
          />
          <div className='vrise-user-profile-progress'>
            <div className='vrise-user-profile-completed'>
              {userData?.profileCompletion}% Profile Completed
            </div>
            <div
              className='progress-bar-colored'
              style={{
                backgroundColor: '#FFEFC8',
                border: true
                  ? 'var(--Spacing-Spacing-none, 1px) solid var(--border-color)'
                  : ''
              }}
            >
              <div
                className='progress-bar-fill'
                style={{
                  width: `${userData?.profileCompletion}%`,
                  backgroundColor: '#F9B300'
                }}
              ></div>
            </div>
            {/* <div style={{ display: 'flex', gap: '4px' }}>
              <span class='material-symbols-outlined'>history</span>
              <p className='vrise-user-profile-updated-time'>
                Profile last updated on: 17 Feb, 2025
              </p>
            </div> */}
          </div>
          <div className='vrise-user-profile-update'>
            <button
              className='vrise-user-profile-update-btn'
              onClick={() => navigate(ROUTES.PROFILE)}
            >
              Update Profile
            </button>
          </div>
        </div>
        <div className='placement-container'>
          <TabHeader
            title={'Placement News'}
            icon={'brand_awareness'}
            backgroundImage={
              'https://staging.herovired.com/wp-content/uploads/2025/03/placement_bg.png'
            }
            textColor={'#fdfdfd'}
            className='sticky-header-vrise'
          />
          {placedLearners.map((learner) => (
            <div className='placement-news'>
              {/* <div className='placement-news-date'>23 Feb, 2025</div> */}
              <div
                className='placement-news-name'
                dangerouslySetInnerHTML={{ __html: learner }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
