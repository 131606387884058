import React, { useState, useEffect, useRef } from 'react';
import './consoleOutput.scss';

function ConsoleOutput({ messages, shouldAnimate }) {
  const [displayText, setDisplayText] = useState(
    'Run your Code to see the output here..'
  );
  const indexRef = useRef(0);

  const isDefaultMessage =
    displayText === 'Run your Code to see the output here..';
  useEffect(() => {
    if (!messages.length) {
      setDisplayText('Run your Code to see the output here..');
      return;
    }

    if (!shouldAnimate) {
      setDisplayText(messages.join('<br/>'));
      return;
    }

    setDisplayText('');
    indexRef.current = 0;
    let messageString = messages.join('<br/>');
    let interval = setInterval(() => {
      setDisplayText((prev) => prev + messageString[indexRef.current]);
      indexRef.current++;

      if (indexRef.current >= messageString.length) {
        clearInterval(interval);
      }
    }, 5);

    return () => clearInterval(interval);
  }, [messages, shouldAnimate]);

  return (
    <div
      className={`console-output ${isDefaultMessage ? 'default-message' : ''}`}
    >
      <div dangerouslySetInnerHTML={{ __html: displayText }} />
    </div>
  );
}

export default ConsoleOutput;
