import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';
// import { Flex, Image } from 'theme-ui';
import { ROUTES } from './config/routes';
import Temp from './components/Temp/Temp';
import path from 'path-browserify';
import VRise from './pages/VRise';
import ApplicationForm from './pages/VRise/ApplicationForm';
import HandsonTableParent from './components/HandsonTableParent/HandsonTableParent';
// import splashScreen from './asset/images/splashScreenGif.gif';

const Layout = lazy(() => import('./layouts/Layout'));
const Login = lazy(() => import('./layouts/loginLayout/LoginLayout'));

const AuthComponent = lazy(() =>
  import('./components/AuthComponent/AuthComponent')
);
const AttendanceDrawerView = lazy(() =>
  import('./components/DrawerView/AttendanceDrawerView')
);
const PageActivity = lazy(() => import('./features/PageActivity/PageActivity'));
const CalenderSync = lazy(() => import('./features/CalenderSync/CalenderSync'));
const ProgramSearch = lazy(() =>
  import('./features/ProgramSearch/ProgramSearch')
);

const Error500 = lazy(() => import('./pages/Error500'));
const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard1'));
const ForumDiscussion = lazy(() =>
  import('./pages/Dashboard/Forum/ForumDiscussion')
);
const Progress = lazy(() => import('./pages/Progress'));
const Error404 = lazy(() => import('./pages/Error404'));
const Error401 = lazy(() => import('./pages/Error401'));
const ProfileWidget = lazy(() => import('./pages/ProfileWidget'));
const ProgramTimeline = lazy(() => import('./pages/ProgramTimeline'));
const ZOOM = lazy(() => import('./pages/Zoom/Zoom'));
const LearningHub = lazy(() => import('./pages/LearningHub'));
const SkillReport = lazy(() => import('./pages/SkillReport/SkillReport'));
const RaiseATicket = lazy(() => import('./pages/RaiseATicket/RaiseATicket'));
const LearningHubArtical = lazy(() =>
  import('./pages/LearningHub/LearningHubArtical')
);
const ExcelPlayground = lazy(() =>
  import('./components/ExcelPlayground/ExcelPlayground')
);
const Quiz = lazy(() =>
  import('./features/PageActivity/QuizActivity/Quiz/Quiz')
);
const ReviewScreen = lazy(() =>
  import('./features/PageActivity/QuizActivity/Review/Review')
);
const ResetPassword = lazy(() => import('./pages/ResetPassword'));
const ReferNEarn = lazy(() => import('./pages/ReferNEarn'));
const MyReferral = lazy(() => import('./pages/ReferNEarn/MyReferral'));
const Router = () => {
  // Need to refactor this further for auth and main layout splitting
  const routes = [
    {
      path: ROUTES.ERROR_500,
      element: <Error500 />
    },
    {
      path: ROUTES.ERROR_401,
      element: <Error401 />
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword />
    },
    {
      path: ROUTES.HOME,
      element: (
        <AuthComponent>
          <Layout />
        </AuthComponent>
      ),
      children: [
        {
          index: true,
          element: <Dashboard />
        },
        {
          path: ROUTES.PAGE_ACTIVITY,
          element: (
            <AuthComponent>
              <PageActivity />
            </AuthComponent>
          )
        },
        {
          path: ROUTES.FORUM_DISCUSSION,
          element: <ForumDiscussion />
        },
        {
          path: ROUTES.PROGRESS_HUB,
          element: <Progress />
        },
        {
          path: ROUTES.ATTENDANCE_DETAILS,
          element: <AttendanceDrawerView />
        },
        {
          path: ROUTES.PROGRAM_TIMELINE,
          element: <ProgramTimeline />
        },
        {
          path: ROUTES.PROGRAM_SEARCH,
          element: <ProgramSearch />
        },
        {
          path: ROUTES.LEARNING_HUB,
          element: <LearningHub />
        },
        {
          path: ROUTES.RAISE_A_TICKET,
          element: <RaiseATicket />
        },
        {
          path: ROUTES.SKILL_REPORT,
          element: <SkillReport />
        },
        {
          path: ROUTES.LEARNING_HUB_ARTICAL,
          element: <LearningHubArtical />
        },
        {
          path: ROUTES.CALENDER_SYNC,
          element: <CalenderSync />
        },
        {
          path: ROUTES.TEMP,
          element: <Temp />
        },
        {
          path: ROUTES.VRISE,
          element: <VRise />
        },
        {
          path: ROUTES.APPLICATION_FORM,
          element: <ApplicationForm />
        },
        {
          path: ROUTES.REFER_N_EARN,
          element: (
            <AuthComponent>
              <ReferNEarn />
            </AuthComponent>
          )
        },
        {
          path: ROUTES.MY_REFERRALS,
          element: (
            <AuthComponent>
              <MyReferral />
            </AuthComponent>
          )
        },
        {
          path: ROUTES.PROFILE,
          element: (
            <AuthComponent>
              <ProfileWidget />
            </AuthComponent>
          )
        },
        {
          path: '*',
          element: <Error404 />
        }
      ]
    },
    {
      path: ROUTES.ZOOM,
      element: (
        <AuthComponent>
          <ZOOM />
        </AuthComponent>
      )
    },
    {
      path: ROUTES.HANDSON,
      element: (
        <AuthComponent>
          <ExcelPlayground />
        </AuthComponent>
      )
    },
    {
      path: ROUTES.QUIZ,
      element: (
        <AuthComponent>
          <Quiz />
        </AuthComponent>
      )
    },
    {
      path: ROUTES.QUIZ_REVIEW_SCREEN,
      element: (
        <AuthComponent>
          <ReviewScreen />
        </AuthComponent>
      )
    },

    {
      path: ROUTES.TEMP,
      element: (
        <AuthComponent>
          <ExcelPlayground />
        </AuthComponent>
      )
    },

    {
      path: ROUTES.LOGIN,
      element: <Login />
    }
  ];

  const element = useRoutes(routes);
  return element;
};

export default Router;
