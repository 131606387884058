import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { METHODS, SERVICES } from '../../api/constants';
import { makeRequest } from '../../api/api';
import CryptoJS from 'crypto-js';

export const fetchCareerDashBoard = createAsyncThunk(
  'vriseSlice/fetchCareerDashBoard',
  async (param) => {
    try {
      const hash = CryptoJS.HmacSHA256(
        JSON.stringify(param.userId),
        '2d1e1v2e4s3h8zkkhehkod2d02fhjewjbd2whj4'
      ).toString(CryptoJS.enc.Hex);

      const response = await makeRequest(
        SERVICES.VRISE_SERVICE,
        `/dashboard/${param.userId}`,
        METHODS.GET,
        {},
        { ...param },
        [{ key: 'signature', value: hash }]
      );
      return { data: response.data };
    } catch (e) {}
  }
);

export const fetchJobByType = createAsyncThunk(
  'vriseSlice/fetchJobByType',
  async (param) => {
    try {
      const hash = CryptoJS.HmacSHA256(
        JSON.stringify(param.userId),
        '2d1e1v2e4s3h8zkkhehkod2d02fhjewjbd2whj4'
      ).toString(CryptoJS.enc.Hex);

      const response = await makeRequest(
        SERVICES.VRISE_SERVICE,
        `/dashboard/${param.userId}/${param.type}/${param.offset}`,
        METHODS.GET,
        {},
        { ...param },
        [{ key: 'signature', value: hash }]
      );
      return { data: response.data };
    } catch (e) {}
  }
);

export const fetchJD = createAsyncThunk('vriseSlice/fetchJD', async (param) => {
  try {
    const hash = CryptoJS.HmacSHA256(
      JSON.stringify(param.userId),
      '2d1e1v2e4s3h8zkkhehkod2d02fhjewjbd2whj4'
    ).toString(CryptoJS.enc.Hex);

    const response = await makeRequest(
      SERVICES.VRISE_SERVICE,
      `${param.userId}/jdDetails/${param.jdId}`,
      METHODS.GET,
      {},
      { ...param },
      [{ key: 'signature', value: hash }]
    );
    return { data: response.data };
  } catch (e) {}
});

export const fetchApplicationForm = createAsyncThunk(
  'vriseSlice/fetchApplicationForm',
  async (param) => {
    try {
      const hash = CryptoJS.HmacSHA256(
        JSON.stringify(param.userId),
        '2d1e1v2e4s3h8zkkhehkod2d02fhjewjbd2whj4'
      ).toString(CryptoJS.enc.Hex);

      const response = await makeRequest(
        SERVICES.VRISE_SERVICE,
        `${param.userId}/responseForm/${param.hashKey}`,
        METHODS.GET,
        {},
        { ...param },
        [{ key: 'signature', value: hash }]
      );
      return { data: response.data };
    } catch (e) {}
  }
);

export const postApplicationForm = createAsyncThunk(
  'vriseSlice/postApplicationForm',
  async (param) => {
    try {
      const hash = CryptoJS.HmacSHA256(
        JSON.stringify(param.userId),
        '2d1e1v2e4s3h8zkkhehkod2d02fhjewjbd2whj4'
      ).toString(CryptoJS.enc.Hex);

      const response = await makeRequest(
        SERVICES.VRISE_SERVICE,
        `${param.userId}/responseForm/submit/${param.hashKey}`,
        METHODS.POST,
        {},
        {
          ...param.payload
        },
        [{ key: 'signature', value: hash }]
      );
      return { data: response.data };
    } catch (e) {}
  }
);

export const vriseSlice = createSlice({
  name: 'vrise',
  initialState: {
    loading: false,
    success: true,
    data: [],
    fetchJD: {
      isJDLoading: false,
      jdData: {},
      jdId: null
    },
    fetchJobByType: {
      isLoading: false,
      data: []
    },
    fetchApplicationForm: {
      isLoading: false,
      data: []
    },
    postApplicationForm: {
      isLoading: false,
      data: []
    }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCareerDashBoard.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCareerDashBoard.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data; // Replace state with fetched data
      state.success = true;
    });
    builder.addCase(fetchCareerDashBoard.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(fetchJD.pending, (state) => {
      state.fetchJD.isJDLoading = true;
    });
    builder.addCase(fetchJD.fulfilled, (state, action) => {
      state.fetchJD.isJDLoading = false;
      state.fetchJD.jdData = action.payload.data;
    });
    builder.addCase(fetchJD.rejected, (state) => {
      state.fetchJD.isJDLoading = false;
    });
    builder.addCase(fetchJobByType.pending, (state) => {
      state.fetchJobByType.isLoading = true;
    });
    builder.addCase(fetchJobByType.fulfilled, (state, action) => {
      state.fetchJobByType.isLoading = false;
      state.fetchJobByType.data = action.payload.data;
    });
    builder.addCase(fetchJobByType.rejected, (state) => {
      state.fetchJobByType.isLoading = false;
    });
    builder.addCase(fetchApplicationForm.pending, (state) => {
      state.fetchApplicationForm.isLoading = true;
    });
    builder.addCase(fetchApplicationForm.fulfilled, (state, action) => {
      state.fetchApplicationForm.isLoading = false;
      state.fetchApplicationForm.data = action.payload.data;
    });
    builder.addCase(fetchApplicationForm.rejected, (state) => {
      state.fetchApplicationForm.isLoading = false;
    });
    builder.addCase(postApplicationForm.pending, (state) => {
      state.postApplicationForm.isLoading = true;
    });
    builder.addCase(postApplicationForm.fulfilled, (state, action) => {
      state.postApplicationForm.isLoading = false;
      state.postApplicationForm.data = action.payload.data;
    });
    builder.addCase(postApplicationForm.rejected, (state) => {
      state.postApplicationForm.isLoading = false;
    });
  }
});

export const { resetSkillTestLoadingState } = vriseSlice.actions;
export default vriseSlice.reducer;
