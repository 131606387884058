import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { makeRequest } from '../../api/api';
import { METHODS, SERVICES } from '../../api/constants';
import { commonMoodlePayload } from '../../config';
import {
  MOODLE_BACKEND_ROUTES,
  VASSIST_BACKEND_ROUTES
} from '../../config/apiRoutes';
import SuccessToastMessage from '../../@common/SuccessToast/SuccessToast';
import { toast } from 'react-toastify';
import { parseLSQData } from '../../constant/profileWidgetConstant';
import { ACCESS_TYPE, handleEnabledState } from '../../constant/constant';
import { createCleverTapProfile } from '../../utils/cleverTap';

export const fetchPolicyDetails = createAsyncThunk(
  'token/fetchPolicyDetails',
  async (param) => {
    // console.log("fetch param",param)
    try {
      const response = await makeRequest(
        SERVICES.MOODLE_SERVICE,
        '',
        METHODS.POST,
        {
          ...param,
          ...commonMoodlePayload,
          wsfunction: MOODLE_BACKEND_ROUTES.GET_POLICY_DETAILS
        }
      );
      return { data: response.data };
    } catch (e) {}
  }
);

export const postProfileDetails = createAsyncThunk( // no longer in use
  'token/postProfileDetails',
  async (data, thunkApi) => {
    const response = await makeRequest(
      SERVICES.VASSIST_SERVICE,
      VASSIST_BACKEND_ROUTES.POST_PROFILE_DATA,
      METHODS.POST,
      {},
      data
    );
    if (response.status === 200) {
      thunkApi.dispatch(setLSQ({ isLsqData: false, data: {}, dataSync: true }));
      return data;
    }
    return response.data;
  }
);

export const GET_LSQ_PROFILE_DETAILS = (data) => { // no longer in use
  return new Promise(async (resolve, reject) => {
    try {
      const response = await makeRequest(
        SERVICES.VASSIST_SERVICE,
        VASSIST_BACKEND_ROUTES.GET_LSQ_PROFILE_DETAILS,
        METHODS.GET,
        data
      );
      if (response.status === 200) {
        if (response?.data?.length) {
          const data = await parseLSQData(response?.data[0]);
          if (data?.phone?.length) {
            resolve({ ...response, data: data });
          } else {
            reject({});
          }
        } else {
          reject(response);
        }
      } else {
        reject(response);
      }
    } catch (e) {
      reject(e);
    }
  });
};

// Need to use promise and to make it work for every pre-written code avoiding createasyncthunk and using normal promises
// Due to which need to pass state and dispatch.
// If you move to redux just return where is resolve and thunkApi.rejectWithvalue on reject else all logic will be same.
export const GET_PROFILE_DETAILS = (data, dispatch, lsqDataParam) => {
  return new Promise(async (resolve, reject) => {
    dispatch(setProfileLoading(true));
    if (lsqDataParam?.isLsqData) {
      resolve({ status: 200, data: lsqDataParam?.lsqData });
      dispatch(setProfileLoading(false));
      return;
    }
    try {
      const profileData = await makeRequest(
        SERVICES.VLEARN_PROFILE_SERVICE,
        VASSIST_BACKEND_ROUTES.GET_PROFILE_DATA_NEW,
        METHODS.POST,
        {},
        data,
        [{ key: 'x-hv-secret-key', value: 'bm8dmzbvtbCOZnrFYZoKp7RyoQavlid4' }]
      );
      if (profileData.status === 200) {
        dispatch(setProfileData(profileData?.data?.data));
        dispatch(
          setTabEnabledState(handleEnabledState(profileData?.data?.data))
        );
        resolve({ ...profileData, data: profileData?.data?.data });
        const {
          firstName,
          lastName,
          gender,
          phone,
          emailAddress,
          dateOfBirth
        } = profileData?.data?.data;
        const profileCreated = sessionStorage.getItem('profileCreated');
        if (!profileCreated) {
          createCleverTapProfile({
            Name: firstName + lastName,
            Phone: '+91' + phone,
            'First Name': firstName,
            'Last Name': lastName,
            Gender: gender[0],
            DOB: dateOfBirth ? new Date(dateOfBirth.split(',')[0]) : undefined,
            'Phone Number': '+91' + phone,
            Email: emailAddress
          });
        }
        dispatch(setProfileLoading(false));
        return;
      } 
      // else if (profileData.status === 204 && !lsqDataParam?.isLsqData) {
      //   if (lsqDataParam?.accessType === ACCESS_TYPE.Premium) {
      //     console.log('asFDSGADFS');

      //     // LSQ api call
      //     dispatch(lsqLoading(true));
      //     await GET_LSQ_PROFILE_DETAILS(data)
      //       .then((res) => {
      //         dispatch(
      //           setLSQ({ isLsqData: true, data: res?.data, loading: false })
      //         );
      //         resolve(res);
      //         dispatch(postProfileDetails(res?.data));
      //       })
      //       .catch((e) => {
      //         dispatch(setLSQ({ isLsqData: false, data: {}, loading: false }));
      //         reject(e);
      //       });
      //   }
      //   resolve(profileData);
      //   dispatch(setProfileLoading(false));
      //   return;
      // }
    } catch (e) {
      const { response } = e;

      // if (response?.status === 404 && !lsqDataParam?.isLsqData) {
      //   console.log('asFDSGADFS');
        
      //   dispatch(lsqLoading(true));
      //   await GET_LSQ_PROFILE_DETAILS(data)
      //     .then((res) => {
      //       dispatch(
      //         setLSQ({ isLsqData: true, data: res?.data, loading: false })
      //       );
      //       resolve(res);
      //       dispatch(postProfileDetails(res?.data));
      //     })
      //     .catch((e) => {
      //       dispatch(setLSQ({ isLsqData: false, data: {}, loading: false }));
      //       reject(e);
      //     });
      //   dispatch(setProfileLoading(false));
      //   return;
      // }
      reject(e);
      dispatch(setProfileLoading(false));
    }
  });
};
export const postPolicyDetails = createAsyncThunk(
  'token/postPolicyDetails',
  async (param) => {
    try {
      const response = await makeRequest(
        SERVICES.MOODLE_SERVICE,
        '',
        METHODS.POST,
        {
          ...param,
          ...commonMoodlePayload,
          wsfunction: MOODLE_BACKEND_ROUTES.POST_POLICY_DETAILS
        }
      );
      return { data: response.data };
    } catch (e) {}
  }
);

export const getProfileDetails = createAsyncThunk(
  'profileDetails/getProfileDetails',
  async (param, thunkApi) => {
    try {
      const lpsData = thunkApi.getState()?.program?.programData;
      let lpidNameMapping = {};
      lpsData?.forEach((obj) => (lpidNameMapping[obj?.lpid] = obj?.lpname));

      const profileData = await makeRequest(
        SERVICES.VLEARN_PROFILE_SERVICE,
        VASSIST_BACKEND_ROUTES.GET_PROFILE_DATA_NEW,
        METHODS.POST,
        {},
        {
          filter: {
            phone: param.phone,
            userId: thunkApi.getState()?.auth?.userData?.userid
          },
          payload: {
            enrolledLearningPaths: lpidNameMapping,
            userId: thunkApi.getState()?.auth?.userData?.userid,
            phone: param.phone,
            emailAddress: thunkApi.getState()?.auth?.userData?.email
          }
        },
        [{ key: 'x-hv-secret-key', value: 'bm8dmzbvtbCOZnrFYZoKp7RyoQavlid4' }]
      );
      if (profileData?.status === 200) {
        thunkApi.dispatch(
          setTabEnabledState(handleEnabledState(profileData?.data?.data))
        );
      }
      return { data: profileData.data };
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const submitProfileDetails = createAsyncThunk(
  'submitProfileDetails/submitProfileDetails',
  async (data1, thunkApi) => {
    const lpsData = thunkApi.getState()?.program?.programData;
    let lpidNameMapping = {};
    lpsData?.forEach((obj) => (lpidNameMapping[obj?.lpid] = obj?.lpname));
    let data = data1?.cb ? data1?.data : data1;
    let temp = data?.dateOfBirth?.split('-');
    data.dateOfBirth =
      temp[temp?.length - 1]?.length >= 4
        ? temp?.reverse()?.join('-')
        : data?.dateOfBirth;

    const formattedPhone = data?.phone.replace('+91-', '');
    const response = await makeRequest(
      SERVICES.VLEARN_PROFILE_SERVICE,
      VASSIST_BACKEND_ROUTES.POST_PROFILE_DATA_NEW,
      METHODS.POST,
      {},
      {
        ...data,
        profileCompletion: thunkApi.getState().profile.profileCompletionRedux,
        userId: thunkApi.getState()?.auth?.userData?.userid,
        enrolledLearningPaths: lpidNameMapping
      },
      [{ key: 'x-hv-secret-key', value: 'bm8dmzbvtbCOZnrFYZoKp7RyoQavlid4' }]
    );
    if (
      response.status === 200 &&
      !(response?.data?.errorcode || response?.data?.exception)
    ) {
      thunkApi.dispatch(getProfileDetails({ phone: formattedPhone }));
      if (data1?.cb) {
        data1?.cb();
      }
      return data;
    }
    return response.data;
  }
);

export const userSlice = createSlice({
  name: 'token',
  initialState: {
    userToken: '',
    policyDetails: [],
    policyLoading: false,
    policyAcceptedSuccess: false,
    isLsqData: false,
    lsqData: {},
    isLsqLoading: false,
    isDataSync: false,
    profileLoading: false,
    profileData: {},
    tabEnabledState: {
      isPersonalDetailsEnabled: true,
      isProfessionalDetailsEnabled: false,
      isEducationalQualificationsEnabled: false,
      isSkillProficiencyEnabled: false,
      isCareersInformationEnabled: false
    },
    profileDetails: {
      isLoading: false,
      profileData: {},
      error: ''
    }
  },
  reducers: {
    userToken: (state, action) => {
      state.userToken = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },
    setTabEnabledState: (state, action) => {
      state.tabEnabledState = action.payload;
    },
    setLSQ: (state, action) => {
      state.isLsqData = action.payload.isLsqData;
      state.lsqData = action.payload.data;
      state.isLsqLoading = action.payload.loading || state.isLsqLoading;
      if (action.payload?.dataSync) {
        state.isDataSync = action.payload.dataSync;
      }
    },
    lsqLoading: (state, action) => {
      state.isLsqLoading = action.payload;
    },
    setProfileLoading: (state, action) => {
      state.isLsqLoading = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPolicyDetails.pending, (state) => {
      state.policyLoading = true;
    });
    builder.addCase(fetchPolicyDetails.fulfilled, (state, action) => {
      state.policyLoading = false;
      if (action?.payload?.data?.length) {
        state.policyDetails = action?.payload?.data;
      }
    });
    builder.addCase(fetchPolicyDetails.rejected, (state) => {
      state.policyLoading = false;
    });
    builder.addCase(postPolicyDetails.pending, (state) => {
      state.policyLoading = true;
    });
    builder.addCase(postPolicyDetails.fulfilled, (state, action) => {
      state.policyLoading = false;
      if (action.payload.data.status === 'Success') {
        state.policyAcceptedSuccess = true;
      } else {
        toast.error(action.payload.data.status || 'Something went wrong');
      }
    });
    builder.addCase(postPolicyDetails.rejected, (state) => {
      state.policyLoading = false;
    });
    builder.addCase(getProfileDetails.pending, (state) => {
      state.profileDetails.isLoading = true;
      state.profileData.leanerApiData = {};
    });
    builder.addCase(getProfileDetails.fulfilled, (state, action) => {
      state.profileDetails.isLoading = false;
      state.profileData.leanerApiData = action?.payload?.data?.data || {};
    });
    builder.addCase(getProfileDetails.rejected, (state) => {
      state.profileDetails.isLoading = false;
      state.profileData.leanerApiData = {};
    });
  }
});

// Action creators are generated for each case reducer function
export const {
  userToken,
  setLSQ,
  lsqLoading,
  setProfileLoading,
  setProfileData,
  setTabEnabledState
} = userSlice.actions;

export default userSlice.reducer;
