import { useEffect, useState } from 'react';

const FreshdeskWidget = () => {
  useEffect(() => {
    if (!document.getElementById('freshdesk-widget-script')) {
      window.fwSettings = { widget_id: 89000001103 };

      const script = document.createElement('script');
      script.id = 'freshdesk-widget-script';
      script.src = 'https://ind-widget.freshworks.com/widgets/89000001103.js';
      script.async = true;
      script.defer = true;

      document.body.appendChild(script);
    }
  }, []);

  return null;
};

export default FreshdeskWidget;
