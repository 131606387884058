import  { useEffect, useMemo, useState } from 'react';
import './pagination.scss';

function Pagination({
  data,
  itemsPerPage,
  getCurrentItems,
  totalCount,
  defaultPage,
  isHavingAllData = false,
  onPageChange = undefined
}) {
  const [currentPage, setCurrentPage] = useState(1 || defaultPage);
  const page = useMemo(() => defaultPage, [defaultPage]);

  useEffect(() => {
    if (page !== currentPage && onPageChange) {
      setCurrentPage(page);
    }
  }, [page, currentPage]);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;

  const totalPages = Math.ceil(totalCount / itemsPerPage);

  const renderPaginationControls = () => {
    const paginationControls = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        paginationControls.push(
          <div
            key={i}
            onClick={() => handlePageChange(i)}
            className={
              currentPage === i ? `pagination-tile-active` : `pagination-tile`
            }
          >
            {i}
          </div>
        );
      }
    } else {
      paginationControls.push(
        <div
          key={1}
          onClick={() => handlePageChange(1)}
          className={
            currentPage === 1 ? `pagination-tile-active` : `pagination-tile`
          }
        >
          1
        </div>
      );

      if (currentPage > 3) {
        paginationControls.push(<span key='startDots' style={{color: "var(--tertiary-text-color)"}}>...</span>);
      }

      for (
        let i = Math.max(2, currentPage - 2);
        i <= Math.min(totalPages - 1, currentPage + 2);
        i++
      ) {
        paginationControls.push(
          <div
            key={i}
            onClick={() => handlePageChange(i)}
            className={
              currentPage == i ? `pagination-tile-active` : `pagination-tile`
            }
          >
            {i}
          </div>
        );
      }

      if (currentPage < totalPages - 2) {
        paginationControls.push(<span key='endDots' style={{color: "var(--tertiary-text-color)"}}>...</span>);
      }

      paginationControls.push(
        <div
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
          className={
            currentPage === totalPages
              ? `pagination-tile-active`
              : `pagination-tile`
          }
        >
          {totalPages}
        </div>
      );
    }

    return paginationControls;
  };

  const handlePageChange = (pageNumber) => {
    let page = pageNumber || 1;
    if (pageNumber < 1) {
      page = 1;
      setCurrentPage(1);
    } else if (pageNumber > totalPages) {
      page = totalPages;
      setCurrentPage(totalPages);
    } else {
      page = pageNumber;
      setCurrentPage(pageNumber);
      if (isHavingAllData) {
        getCurrentItems(
          data?.slice(
            (pageNumber - 1) * itemsPerPage,
            pageNumber * itemsPerPage
          )
        );
      }
    }
    if (onPageChange) {
      onPageChange(page);
    }
  };

  useEffect(() => {
    if (isHavingAllData && !isNaN(firstIndex) && !isNaN(lastIndex)) {
      getCurrentItems(data?.slice(firstIndex, lastIndex));
    }
  }, [firstIndex, lastIndex]);
  return (
    <div className='pagination-container'>
      <div
        disabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
        className={`pagination-arrow-tile ${
          currentPage === 1 ? 'no-pointer' : ''
        }`}
      >
        <span
          className={`material-symbols-rounded pagiantion-icon ${
            currentPage === 1 ? 'pagination-icon-disabled' : ''
          }`}
        >
          chevron_left
        </span>
      </div>
      {renderPaginationControls()}
      <div
        disabled={lastIndex >= totalCount}
        onClick={() => handlePageChange(currentPage + 1)}
        className={`pagination-arrow-tile ${
          lastIndex >= totalCount ? 'no-pointer' : ''
        }`}
      >
        <span
          className={`material-symbols-rounded pagiantion-icon ${
            lastIndex >= totalCount ? 'pagination-icon-disabled' : ''
          }`}
        >
          chevron_right
        </span>
      </div>
    </div>
  );
}

export default Pagination;
