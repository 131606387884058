import React, { useEffect } from 'react';
import './applyForm.scss';
import { ApplyForm } from './ApplyForm';
import { fetchApplicationForm } from '../../redux/feature/vriseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ContentLoader from 'react-content-loader';

export default function ApplicationForm() {
  const dispatch = useDispatch();
  const { hashId } = useParams();
  const navigate = useNavigate();

  const userId = useSelector((state) => state?.auth?.userData?.userid);
  const userData = useSelector((state) => state?.user?.profileData);
  const { data: applicationFormData } = useSelector(
    (state) => state?.vriseSlice?.fetchApplicationForm
  );

  useEffect(() => {
    if (userId)
      dispatch(
        fetchApplicationForm({
          userId,
          hashKey: hashId
        })
      );
  }, [userId]);

  return (
    <>
      {applicationFormData?.data?.heading ? (
        <div className='error-background'>
          <div className='error-container'>
            <div className='error-heading'>
              {applicationFormData?.data?.heading}
            </div>
            <div className='error-description'>
              {applicationFormData?.data?.description}
            </div>
          </div>
        </div>
      ) : applicationFormData?.data && userData?.leanerApiData ? (
        <div style={{ position: 'relative', zIndex: '1', overflow: 'hidden' }}>
          <div className='apply-now-container'>
            <div className='apply-now-form-back-button'>
              <span
                className='material-symbols-outlined back-button'
                onClick={() => {
                  navigate('/vrise');
                }}
                style={{ cursor: 'pointer' }}
              >
                arrow_back
              </span>
              {applicationFormData?.data?.responseData?.length
                ? 'Your Application'
                : 'You’re Applying for'}
            </div>
            {applicationFormData?.data && userData?.leanerApiData && (
              <ApplyForm
                data={applicationFormData.data}
                filled={Boolean(
                  applicationFormData?.data?.responseData?.length
                )}
                hashKey={hashId}
                userData={userData}
              />
            )}
          </div>
          {/* <img
            src='https://staging.herovired.com/wp-content/uploads/2025/03/form_bg.png'
            width={750}
            height={750}
            style={{
              position: 'absolute',
              top: '20%',
              right: '-12%',
              objectFit: 'contain',
              zIndex: 1
            }}
          /> */}
        </div>
      ) : (
        <ContentLoader
          speed={2}
          width='100%'
          height='100%'
          viewBox='0 0 100% 100%'
          backgroundColor='var(--darktheme-loader-bg, #EFF3F9)'
          foregroundColor='var(--darktheme-loader-foreground, #ecebeb)'
        >
          <rect x='2vw' y='20%' rx='10' ry='10' width='85vw' height='85%' />
        </ContentLoader>
      )}
    </>
  );
}
