import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { METHODS, SERVICES } from '../../api/constants';
import { makeRequest } from '../../api/api';
import { MOODLE_BACKEND_ROUTES } from '../../config/apiRoutes';
import { commonMoodlePayload } from '../../config';

export const fetchProblemDetails = createAsyncThunk(
  'handsonSlice/fetchProblemDetails',
  async (param, thunkApi) => {
    try {
      const response = await makeRequest(
        SERVICES.MOODLE_SERVICE,
        '',
        METHODS.GET,
        {
          ...param,
          ...commonMoodlePayload,
          wsfunction: MOODLE_BACKEND_ROUTES.GET_HANDSON_PROBLEM_DATA,
          wstoken: thunkApi.getState().auth.token
        }
      );

      return response.data;
    } catch (e) {
      thunkApi.rejectWithValue(e);
    }
  }
);

export const createMoodleAttempt = createAsyncThunk(
  'handsonSlice/createMoodleAttempt',
  async (param, thunkApi) => {
    try {
      const response = await makeRequest(
        SERVICES.MOODLE_SERVICE,
        '',
        METHODS.GET,
        {
          ...param,
          ...commonMoodlePayload,
          wsfunction: MOODLE_BACKEND_ROUTES.CREATE_HANDSON_ATTEMPT_ON_MOODLE,
          wstoken: thunkApi.getState().auth.token
        }
      );
      return response.data;
    } catch (e) {
      thunkApi.rejectWithValue(e);
    }
  }
);

export const fetchProblemSet = createAsyncThunk(
  'handsonSlice/fetchProblemSet',
  async (params, thunkApi) => {
    try {
      const response = await makeRequest(
        SERVICES.VAUTH_NODE_APIS,
        `/authoring/fetch`,
        METHODS.GET,
        { ...params },
        {}
      );
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const fetchProblemSubmissions = createAsyncThunk(
  'handsonSlice/fetchProblemSubmissions',
  async (params, thunkApi) => {
    try {
      const response = await makeRequest(
        SERVICES.VAUTH_NODE_APIS,
        `/submission/fetch`,
        METHODS.GET,
        { ...params }
      );
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const createProblemSubmission = createAsyncThunk(
  'handsonSlice/createProblemSubmission',
  async (params, thunkApi) => {
    try {
      const response = await makeRequest(
        SERVICES.VAUTH_NODE_APIS,
        `/submission/create`,
        METHODS.POST,
        {},
        { ...params }
      );
      if (response?.data?.success) {
        thunkApi.dispatch(
          fetchProblemSubmissions({
            problemUserId: params?.problemUserId,
            problemType: 'Excel'
          })
        );
      }
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

// Slice Definition
export const handsonSlice = createSlice({
  name: 'handsonSlice',
  initialState: {
    problemSetData: null,
    isProblemSetLoading: false, // TODO: this is called as soon as page loads, called right after we click on the activity from classroom(after moodle api is called and problem id is obtained -> isMoodleProblemDetailsLoading)         -> 2nd
    fetchProblemSetError: '',
    problemSubmissionData: [],
    isFetchProblemSubmissionLoading: false,    // as soon as page loads, called once the problem id is obtained -> brings submissions ----------------> 2nd
    fetchProblemSubmissionError: '',
    createProblemSubmissionData: null,
    isCreateProblemSubmissionLoading: false,  // on submit button click to save the details on mongodb        --------> 3rd
    createProblemSubmissionError: '',
    moodleProblemDetails: {},
    isMoodleProblemDetailsLoading: false, // TODO: this is called as soon as the page loads, and fetches problem id.              --------> 1st
    moodleProblemDetailsError: null,
    createMoodleAttemtData: {},
    isCreateMoodleAttemptLoading: false, // TODO: once submit button click to save the details on moodle       --------> 3rd
    createMoodleAttemptError: null,
  },
  reducers: {
    resetHandsonState: (state) => {
      return {
          problemSetData: null,
          isProblemSetLoading: false,
          fetchProblemSetError: '',
          problemSubmissionData: [],
          isFetchProblemSubmissionLoading: false,
          fetchProblemSubmissionError: '',
          createProblemSubmissionData: null,
          isCreateProblemSubmissionLoading: false,
          createProblemSubmissionError: '',
          moodleProblemDetails: {},
          isMoodleProblemDetailsLoading: false,
          moodleProblemDetailsError: null,
          createMoodleAttemtData: {},
          isCreateMoodleAttemptLoading: false,
          createMoodleAttemptError: null,
        };
    }},
  extraReducers: (builder) => {
    builder
      // fetchProblemSet
      .addCase(fetchProblemSet.pending, (state) => {
        state.isProblemSetLoading = true;
        state.fetchProblemSetError = null;
      })
      .addCase(fetchProblemSet.fulfilled, (state, action) => {
        state.isProblemSetLoading = false;
        state.problemSetData =  action.payload?.data?.[0];   
      })  
      .addCase(fetchProblemSet.rejected, (state, action) => {
        state.isProblemSetLoading = false;
        state.fetchProblemSetError = action.payload;
      })

      // fetchProblemSubmissions
      .addCase(fetchProblemSubmissions.pending, (state) => {
        state.isFetchProblemSubmissionLoading = true;
        state.fetchProblemSubmissionError = null;
      })
      .addCase(fetchProblemSubmissions.fulfilled, (state, action) => {
        state.isFetchProblemSubmissionLoading = false;
        state.problemSubmissionData = action.payload?.data?.[0] || [];
      })
      .addCase(fetchProblemSubmissions.rejected, (state, action) => {
        state.isFetchProblemSubmissionLoading = false;
        state.fetchProblemSubmissionError = action.payload;
      })

      // createProblemSubmission
      .addCase(createProblemSubmission.pending, (state) => {
        state.createProblemSubmissionData = true;
        state.createProblemSubmissionError = null;
      })
      .addCase(createProblemSubmission.fulfilled, (state, action) => {
        state.isCreateProblemSubmissionLoading = false;
        state.createProblemSubmissionData = action.payload?.data || [];
      })
      .addCase(createProblemSubmission.rejected, (state, action) => {
        state.isCreateProblemSubmissionLoading = false;
        state.createProblemSubmissionError = action.payload;
      })

      // fetchProblemDetails
      .addCase(fetchProblemDetails.pending, (state) => {
        state.isMoodleProblemDetailsLoading = true;
        state.moodleProblemDetailsError = null;
      })
      .addCase(fetchProblemDetails.fulfilled, (state, action) => {
        state.isMoodleProblemDetailsLoading = false;
        state.moodleProblemDetails = action.payload?.data || {};
      })
      .addCase(fetchProblemDetails.rejected, (state, action) => {
        state.isMoodleProblemDetailsLoading = false;
        state.moodleProblemDetails = {};
        state.moodleProblemDetailsError = true;
      })

      // createMoodleAttempt
      .addCase(createMoodleAttempt.pending, (state) => {
        state.isCreateMoodleAttemptLoading = true;
        state.createMoodleAttemptError = null;
      })
      .addCase(createMoodleAttempt.fulfilled, (state, action) => {
        state.isCreateMoodleAttemptLoading = false;
        state.createMoodleAttemtData = action.payload?.data || {};
      })
      .addCase(createMoodleAttempt.rejected, (state, action) => {
        state.isCreateMoodleAttemptLoading = false;
        state.createMoodleAttemtData = {};
        state.createMoodleAttemptError = true;
      });
  }
});
export const { resetHandsonState } = handsonSlice.actions;
export default handsonSlice.reducer;
