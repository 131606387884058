import React, { useState, useEffect } from 'react';
import './tabHeader.scss';
import { cleverTapEventPush } from '../../utils/cleverTap';
import {
  CLEVER_TAP_EVENTS,
  defaultCleverTapValue
} from '../../config/cleverTapEvents';

const dummy = () => {
  // console.log('dummy');
};

const classTypeMap = {
  all: 'Class type',
  1: 'Live sessions',
  2: 'Practice sessions',
  3: 'Placement and orientation sessions',
  4: 'Doubt clearing sessions',
  5: 'Other sessions',
  6: 'Career practice sessions'
};

function TabHeader({
  title,
  cta,
  progress,
  uniqueCourseData,
  onClickFunction = dummy,
  showFilter = false,
  icon = null,
  ctaClass,
  setSelectedCourse,
  setSelectedStatus,
  setSelectedClassType,
  activeClassTypes = Object.keys(classTypeMap),
  backgroundImage = null,
  background = '',
  className = '',
  selectedValueCourseExternal = 'all',
  selectedValueStatusExternal = 'all',
  selectedValueClassTypeExternal = 'all',
  dropdownOptions = [],
  selectedValueDefaultType = 'all',
  onDropdownChange = () => {},
  handlePageChange = dummy,
  showType = false,
  textColor
}) {
  const [selectedValueCourse, setSelectedValueCourse] = useState(
    selectedValueCourseExternal
  );
  const [selectedValueStatus, setSelectedValueStatus] = useState(
    selectedValueStatusExternal
  );
  const [selectedValueClassType, setSelectedValueClassType] = useState(
    selectedValueClassTypeExternal
  );

  const [selectedDropdownValue, setSelectedDropdownValue] = useState(
    selectedValueDefaultType
  );

  const handleDropdownChange = (e) => {
    const newValue = e.target.value;
    setSelectedDropdownValue(newValue);
    onDropdownChange(newValue);
  };

  const handleChangeCourse = (value) => {
    const coursesName = uniqueCourseData?.filter(
      (obj) => Number(obj?.courseId) == Number(value)
    );
    cleverTapEventPush(CLEVER_TAP_EVENTS.Attendance_details_activity, {
      Filter: 'course',
      Category: defaultCleverTapValue,
      course: coursesName?.[0]?.courseName
    });
    setSelectedValueCourse(value);
    handlePageChange(1);
    setSelectedCourse(value);
  };

  const handleChangeStatus = (value) => {
    cleverTapEventPush(CLEVER_TAP_EVENTS.Progress, {
      Attendance_details_activity: 'Filter',
      Category: defaultCleverTapValue,
      status: value
    });
    setSelectedValueStatus(value);
    handlePageChange(1);
    setSelectedStatus(value);
  };
  const handleChangeClassType = (value) => {
    const sessionTypeName = classTypeMap[value];
    cleverTapEventPush(CLEVER_TAP_EVENTS.Progress, {
      Attendance_details_activity: 'Filter',
      Category: defaultCleverTapValue,
      session_type: sessionTypeName
    });
    setSelectedValueClassType(value);
    handlePageChange(1);
    setSelectedClassType(value);
  };
  return (
    <>
      <div
        className={`tab-header ${className}`}
        style={
          backgroundImage
            ? {
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }
            : {
                background: background ? background : ''
              }
        }
      >
        {title && (
          <div className='tab-header__title' style={{ color: textColor }}>
            {icon && (
              <span class='material-symbols-outlined tab-header__icon'>
                {icon}
              </span>
            )}
            {title}
          </div>
        )}
        {progress !== undefined && (
          <div className='tab-header__progress'>
            <div className='tab-header__progress-bar-container'>
              <div
                className='tab-header__progress-bar'
                style={{ width: `${progress}%` }}
              ></div>
            </div>
            <span className='tab-header__progress-text'>{progress}%</span>
          </div>
        )}
        {showType && (
          <div
            className='default-type-filter flex-wrap'
            style={{ gap: '12px', marginRight: 'auto' }}
          >
            <select
              className='custom-select course-name-select'
              id='attendance-course'
              name='attendance-course'
              value={selectedDropdownValue || 'all'}
              onChange={handleDropdownChange}
            >
              {dropdownOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {cta && !showFilter && (
          <div
            className={`tab-header__cta ${ctaClass}`}
            onClick={() => onClickFunction()}
          >
            {cta}
          </div>
        )}

        {showFilter && (
          <div
            className='attendance-details-filter flex-wrap'
            style={{ gap: '12px' }}
          >
            <select
              className='custom-select course-name-select'
              id='attendance-course'
              name='attendance-course'
              value={selectedValueCourse || 'all'}
              onChange={(e) => handleChangeCourse(e.target.value)}
            >
              <option value='all'>Course</option>
              {uniqueCourseData?.length > 0 ? (
                uniqueCourseData.map((element, idx) => (
                  <option key={idx} value={element?.courseId}>
                    {element?.courseName}
                  </option>
                ))
              ) : (
                <option disabled>No courses available</option>
              )}
            </select>
            <select
              className='custom-select status-select'
              id='attendance-status'
              name='attendance-status'
              value={selectedValueStatus || 'all'}
              onChange={(e) => handleChangeStatus(e.target.value)}
            >
              <option value='all'>Status</option>
              <option value='P'>Present</option>
              <option value='A'>Absent</option>
            </select>
            <select
              className='custom-select'
              id='attendance-class-type'
              name='attendance-class-type'
              value={selectedValueClassType || 'all'}
              onChange={(e) => handleChangeClassType(e.target.value)}
            >
              {activeClassTypes?.map((value, index) => {
                return <option value={value}>{classTypeMap[value]}</option>;
              })}
            </select>
          </div>
        )}
      </div>
    </>
  );
}

export default TabHeader;
