
import React, { useEffect } from "react";
import introJs from "intro.js";
import "intro.js/introjs.css";
import "./guidedTour.scss";

const GuidedTour = ({ steps, tourType, onTourEnd }) => {
  useEffect(() => {
    const hasSeenTour = localStorage.getItem("hasSeenTour");
    const hasSeenTabTour = localStorage.getItem("hasSeenTabTour");

    let shouldRunTour = false;

    if (tourType === "preSubmission" && !hasSeenTour) {
      shouldRunTour = true;
    } else if (tourType === "postSubmission" && !hasSeenTabTour) {
      shouldRunTour = true;
    } else {
      console.log("⛔ Tour Not Running: Already Seen.");
    }

    if (shouldRunTour) {
      const checkElementsExist = () => {
        return steps.every((step) => document.querySelector(step.element));
      };
      let tourStarted = false;
      const waitForElements = setInterval(() => {
        if (!tourStarted && checkElementsExist()) {
          tourStarted = true;
          clearInterval(waitForElements);
          const intro = introJs();
          intro.setOptions({
            steps,
            showProgress: true,
            showBullets: false,
            exitOnOverlayClick: false,
            doneLabel: "Finish",
          });

          intro.start();

          setTimeout(() => {
            intro.refresh();
          }, 500);

          const onFinish = () => {
            if (tourType === "preSubmission") {
              localStorage.setItem('hasSeenTour', 'true');
            } else if (tourType === "postSubmission") {
              localStorage.setItem("hasSeenTabTour", "true");
            }
            onTourEnd();
          };

          intro.oncomplete(onFinish);
          intro.onexit(onFinish);
        }
      }, 500);
      return () => clearInterval(waitForElements);
    }
  }, [steps, tourType, onTourEnd]);

  return null;
};

export default GuidedTour;
