import React from 'react';
import './VRiseStatusCard.scss';
import { useNavigate } from 'react-router-dom';

const icon_mapping = {
  location: 'location_on',
  type: 'trip',
  salary: 'currency_rupee'
};

const cvStatusMap = {
  null: { cta: 'Awaited', class: 'vrise-yellow', icon: 'history' },
  awaited: { cta: 'Awaited', class: 'vrise-yellow', icon: 'history' },
  cleared: { cta: 'Cleared', class: 'vrise-green', icon: 'check' },
  rejected: { cta: 'Rejected', class: 'vrise-red', icon: 'close' },
  not_appeared: { cta: 'Rejected', class: 'vrise-red', icon: 'close' },
  undefined: { cta: 'Awaited', class: 'vrise-yellow', icon: 'history' }
};

export const VRiseStatusCard = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className='vcard'>
      <div className='designation'>
        <div className='image-div' />

        <div className='div'>
          <div className='text-wrapper'>{data.job_title}</div>
          <div className='text-wrapper-2'>{data.company}</div>
        </div>
      </div>

      <div style={{ width: '100%', height: '1px' }} className='break-line' />

      <div className='div-2'>
        <div className='info'>
          {Object.keys(data.job_details).map((detail) => (
            <div className='div'>
              <div className='label'>
                <span
                  class='material-symbols-outlined'
                  style={{ fontSize: '18px' }}
                >
                  {icon_mapping[detail]}
                </span>

                <div className='text-wrapper-3'>
                  {detail.charAt(0).toUpperCase() + detail.slice(1)}
                </div>
              </div>

              <div className='text-wrapper-4'>
                {data.job_details[detail] || '--'}
              </div>
            </div>
          ))}
        </div>

        <div className='status'>
          <div className='text-wrapper-5'>
            {data.round_name ? data.round_name : 'Application Status'}
          </div>
          <div className={`tag ${cvStatusMap?.[data.round_status]?.class}`}>
            <span className='material-symbols-outlined status-icon'>
              {cvStatusMap[data.round_status].icon}
            </span>

            <div className='text-wrapper-6'>
              {cvStatusMap[data.round_status].cta}
            </div>
          </div>
        </div>
      </div>

      <div style={{ width: '100%', height: '1px' }} className='break-line' />

      <div className='div-2'>
        <div className='text-wrapper-7'>Applied: {data.applied_date}</div>

        <button className='CTA'>
          <div
            className='text-wrapper-8'
            onClick={() => {
              navigate(`/vrise/${data.hash2}`);
            }}
            style={{ cursor: 'pointer' }}
          >
            View Details
          </div>
          <span class='material-symbols-outlined' style={{ color: '#5095FC' }}>
            chevron_right
          </span>
        </button>
      </div>
    </div>
  );
};
