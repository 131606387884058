import React, { useEffect, useState } from 'react';
import './applyForm.scss';
import AppInput from '../../components/Forms/Inputs/AppInput';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { customToast } from '../../constant/constant';
import { postApplicationForm } from '../../redux/feature/vriseSlice';
import { Text } from 'theme-ui';
import Modal from 'react-modal';
import congratulationLottie from '../../asset/images/congratulationLottie.png';
import { useNavigate } from 'react-router-dom';
import companyFallbackImage from '../../asset/images/company_fallback_image.png';

function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader(); // Create a new FileReader object

    reader.onload = function (event) {
      resolve(event.target.result); // Resolve with the file content as a data URL
    };

    reader.onerror = function (event) {
      reject(event.target.error); // Reject with the error if file reading encounters an error
    };

    // Read the file as a data URL
    reader.readAsDataURL(file);
  });
}

export const ApplyForm = ({ data, filled, hashKey, userData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userId = useSelector((state) => state?.auth?.userData?.userid);

  const defaultValues = data?.questionsData?.reduce((acc, question) => {
    // Set custom defaults for specific questions
    if (question.question_name.includes('Current Location')) {
      acc[question.question_id] = userData?.leanerApiData?.city;
    } else if (question.question_name.includes('Graduation Year')) {
      acc[question.question_id] =
        userData?.leanerApiData?.educationalQualifications?.[2]?.yearOfPassing;
    } else if (question.question_name.includes("Bachelor's Degree")) {
      acc[question.question_id] =
        userData?.leanerApiData?.educationalQualifications?.[2]?.level;
    } else if (question.question_name.includes('LinkedIn')) {
      acc[question.question_id] =
        userData?.leanerApiData?.workExperience?.socialProfiles?.[0]?.profileLink;
    } else if (question.question_name.includes('Last Company')) {
      acc[question.question_id] =
        userData?.leanerApiData?.workExperience?.currentOrganization;
    } else if (question.question_name.includes('Current CTC')) {
      acc[question.question_id] =
        userData?.leanerApiData?.workExperience?.salaryPerAnnum;
    } else if (question.question_name.includes('years of experience')) {
      acc[question.question_id] =
        userData?.leanerApiData?.workExperience?.totalWorkExperience;
    } else {
      acc[question.question_id] = '';
    }
    return acc;
  }, {});

  const { register, handleSubmit, reset } = useForm({
    defaultValues: filled ? {} : defaultValues
  });

  const [checkboxTick, setCheckboxTick] = useState({
    firstBox: false,
    secondBox: false
  });
  const [selectedOption, setSelectedOption] = useState({
    learnerResume: false,
    newResume: false
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resumeData, setResumeData] = useState();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    // const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
    const MAX_FILE_SIZE = 750 * 1024;
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        customToast(
          'File size exceeds 750Kb.',
          'Please select a smaller file.',
          'warning',
          5000
        );
        setResumeData(null);
      } else {
        setResumeData({ file, fileName: file.name });
      }
    }
  };

  const onSubmit = async (formData) => {
    if (!selectedOption.learnerResume && !resumeData?.fileName) {
      customToast(
        'Error',
        'Please Upload Resume or choose existing any resume',
        'error',
        5000
      );
      return;
    }
    const requiredFields = data?.questionsData.filter(
      (question) => question.required == 0
    );
    const error = requiredFields.filter(
      (field) => formData[field.question_id] == ''
    );
    if (!error.length) {
      const questionResponses = [];
      Object.keys(formData).forEach((response) => {
        questionResponses.push({ id: response, response: formData[response] });
      });

      let fileContent;
      if (resumeData?.file)
        fileContent = await readFileAsDataURL(resumeData?.file);

      const responseData = {
        interested: '0',
        learner_remarks: '',
        questionResponses: questionResponses,
        resumeName: resumeData?.fileName || null,
        resume: resumeData?.file ? fileContent : null,
        fileLink: selectedOption?.learnerResume
          ? userData?.leanerApiData?.workExperience?.resumeLocation
          : null
      };
      dispatch(
        postApplicationForm({
          userId,
          hashKey: hashKey,
          payload: responseData
        })
      );
      reset(defaultValues);
      setSelectedOption({ learnerResume: false, newResume: false });
      setIsModalOpen(true);
    } else {
      customToast(
        'Error',
        'Please answer all the required questions',
        'error',
        5000
      );
    }
  };
  useEffect(() => {
    reset(filled ? {} : defaultValues);
  }, [data, filled, reset]);

  const formFields = filled ? data?.responseData : data?.questionsData;

  return (
    <div className='form'>
      <header className='header'>
        <div className='info'>
          <div className='div'>
            <div className='div-2'>
              <div className='image-div'>
                <img className='image' alt='Image' src={companyFallbackImage} />
              </div>
              <div className='text-wrapper'>{data?.jdData?.company}</div>
            </div>
            <div className='text-wrapper-2'>{data?.jdData?.job_title}</div>
          </div>
          <div className='info-2'>
            <div className='div-2'>
              <div className='text-wrapper-3'>
                {data?.jdData?.job_details?.location}
              </div>
              <div
                style={{ background: '#E5EBF4', height: '12px', width: '1px' }}
              />
              <div className='text-wrapper-3'>
                INR {data?.jdData?.job_details?.salary}
              </div>
              <div
                style={{ background: '#E5EBF4', height: '12px', width: '1px' }}
              />
              <div className='text-wrapper-3'>
                {data?.jdData?.job_details?.type}
              </div>
            </div>
            <div className='info-3'>
              <div className='text-wrapper-4'>
                Overall Exp: {data?.jdData?.min_exp}-{data?.jdData?.max_exp}{' '}
                years
              </div>
              <div className='text-wrapper-4'>
                Relevant Exp: {data?.jdData?.min_relevant_exp}-
                {data?.jdData?.max_relevant_exp} years
              </div>
            </div>
          </div>
        </div>
        {filled && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              alignSelf: 'stretch',
              padding: '20px'
            }}
          >
            <p
              className='text-wrapper-4'
              style={{
                fontWeight: '500',
                background:
                  'linear-gradient(180deg, #F2F4FA 50%, rgba(242, 244, 250, 0.00) 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                height: '40px'
              }}
            >
              {data?.jdData?.roles_resp}
            </p>
            <a
              href={data?.jdData?.jd_link}
              target='_blank'
              className='text-wrapper-4'
              style={{
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                color: '#fdfdfd'
              }}
            >
              View Job Description
              <span class='material-symbols-outlined'>chevron_right</span>
            </a>
          </div>
        )}
      </header>

      <div
        className='section'
        style={{ padding: filled ? '24px' : '24px 24px 0px 24px' }}
      >
        <div className='profile-section'>
          <div className='user-data'>
            <img
              src={userData.profileImg}
              alt='user_image'
              className='profile'
            />
            <div className='name-linkedin'>
              <div className='text-wrapper-6'>
                {data?.learnerData?.learner_name}
              </div>
              <div className='div'>
                <div className='div-3'>
                  <span className='material-symbols-outlined'>call</span>
                  <div className='text-wrapper-7'>
                    +91 {data?.learnerData?.phone_number}
                  </div>
                </div>
                <div className='div-3'>
                  <span className='material-symbols-outlined'>mail</span>
                  <div className='text-wrapper-7'>
                    {data?.learnerData?.learner_email}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {filled && (
            <div className='submitted-resume-container'>
              <div className='pdf'>
                <img
                  className='group'
                  alt='Group'
                  src='https://staging.herovired.com/wp-content/uploads/2025/03/pdf.png'
                />
              </div>
              <a
                className='submitted-resume-text'
                href={data?.resumeData?.[0]?.resume}
                target='_blank'
              >
                Submitted Resume
              </a>
            </div>
          )}
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '24px',
            alignSelf: 'stretch'
          }}
        >
          {!filled && (
            <div className='input'>
              <div className='div-4'>Select Your Resume</div>
              <div className='files'>
                {userData?.leanerApiData?.workExperience?.resumeLocation ? (
                  <>
                    <div className='file-info'>
                      <div className='div-2'>
                        <input
                          type='radio'
                          className='radio-button-instance'
                          name='contact'
                          onChange={(e) => {
                            setSelectedOption({
                              learnerResume: e.target.checked,
                              newResume: false
                            });
                          }}
                        />
                        <div className='pdf'>
                          <img
                            className='group'
                            alt='Group'
                            src='https://staging.herovired.com/wp-content/uploads/2025/03/pdf.png'
                          />
                        </div>
                        <div className='text-wrapper-8'>Resume-file.pdf</div>
                      </div>
                      <a
                        className='text'
                        href={
                          userData?.leanerApiData?.workExperience
                            ?.resumeLocation
                        }
                        target='_blank'
                        style={{ textDecoration: 'none' }}
                      >
                        View
                      </a>
                    </div>
                    <div className='text-wrapper-9'>or</div>
                  </>
                ) : null}
                <div className='file-info-2'>
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'center'
                    }}
                  >
                    {resumeData?.fileName && (
                      <input
                        type='radio'
                        className='radio-button-instance'
                        name='contact'
                        onChange={(e) => {
                          setSelectedOption({
                            learnerResume: false,
                            newResume: e.target.checked
                          });
                        }}
                      />
                    )}
                    <div className='text-wrapper-10'>
                      {resumeData?.fileName
                        ? resumeData.fileName
                        : 'Upload a new resume'}
                    </div>
                  </div>
                  <input
                    type='file'
                    style={{ display: 'none', width: '100%' }}
                    id='resume-upload'
                    onChange={handleFileChange}
                    accept='application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  />
                  <label htmlFor='resume-upload'>
                    <img
                      className='publish'
                      alt='Publish'
                      src='https://c.animaapp.com/TULCUWvB/img/publish@2x.png'
                      style={{ cursor: 'pointer' }}
                    />
                  </label>
                </div>
              </div>
            </div>
          )}

          <div className='input-fields'>
            {formFields?.map((question, index) => {
              return (
                <AppInput
                  key={index}
                  label={question.question_name}
                  placeholder={filled ? null : question.help_text}
                  type={question.response_type}
                  name={question?.question_id}
                  required={!question.required}
                  register={register}
                  disabled={filled}
                  value={filled ? question.response : undefined}
                />
              );
            })}
          </div>
          {!filled && (
            <div
              className='bottom'
              style={{ borderRadius: '0px 0px 24px 24px' }}
            >
              <div className='t-and-c'>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    id='policy-checkbox-1'
                    onChange={(e) =>
                      setCheckboxTick({
                        ...checkboxTick,
                        firstBox: e.target.checked
                      })
                    }
                  />
                  <p className='div-4'>
                    I confirm that my information available with Hero Vired is
                    updated and most recent
                  </p>
                </div>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    id='policy-checkbox-2'
                    onChange={(e) =>
                      setCheckboxTick({
                        ...checkboxTick,
                        secondBox: e.target.checked
                      })
                    }
                  />
                  <p className='div-4'>
                    I have read the job description thoroughly and hereby agree
                    to register for this job role
                  </p>
                </div>
              </div>

              <button
                className='button'
                type='submit'
                style={{
                  ...(checkboxTick.firstBox && checkboxTick.secondBox
                    ? {
                        background: '#5095fc'
                      }
                    : {})
                }}
                disabled={!(checkboxTick.firstBox && checkboxTick.secondBox)}
              >
                <div className='text-2'>Submit</div>
              </button>
            </div>
          )}
        </form>
      </div>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          className='profile-confirmation-modal'
          style={{
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: '999',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            },
            content: {
              margin: 'auto',
              overflow: 'auto',
              position: 'relative',
              top: 'auto',
              left: 'auto',
              right: 'auto',
              bottom: 'auto'
            }
          }}
        >
          <img src={congratulationLottie} />
          <Text
            sx={{
              color: 'var(--secondary-text-color)',
              textAlign: 'center',
              fontFamily: 'Semi Sans',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '140%'
            }}
          >
            Your details are submitted successfully
          </Text>
          <div className='profile-confirmation-cta'>
            <button
              className='profile-button-text-save'
              onClick={() => {
                navigate('/vrise');
              }}
            >
              Back to Home
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
